import React, { useEffect } from "react";
import { useTopicRating } from "hooks/api";
import MyRating from "./MyRating";

const TopicRating = ({ topic }) => {
  const [getRating, setRating, rating] = useTopicRating();

  useEffect(() => {
    async function fetchRating() {
      await getRating(topic.id);
    }
    fetchRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MyRating
      rating={rating}
      onChange={(event, newValue) => {
        setRating(topic.id, newValue || 0);
      }}
    />
  );
};

export default TopicRating;
