import React from "react";
import NavigationBmr from "./NavigationBmr";
import NavigationL2e from "./NavigationL2e";
import { Page } from "advancement-solutions-components/dist/components";

const Component = ({ user }) => {
  const brand = process.env.REACT_APP_BRAND;
  return (
    <Page user={user}>
      {brand === "bmr" && <NavigationBmr />}
      {brand === "l2e" && <NavigationL2e />}
    </Page>
  );
};

export default Component;
