import React from "react";
import List from "@mui/material/List";
import { useGetChapters } from "hooks/api";
import Chapter from "./Chapter";

const Chapters = ({ locked, group, topic }) => {
  const [chapters, isLoadingChapters, chaptersError] = useGetChapters(
    group,
    topic.id
  );
  return (
    <List>
      {!isLoadingChapters &&
        chapters.length > 0 &&
        chapters.map((chapter) => (
          <Chapter
            key={chapter.id}
            locked={locked}
            group={group}
            chapter={chapter}
          />
        ))}
    </List>
  );
};

export default Chapters;
