export const gatherStats = (progress) => {
  return {
    flashcards: {
      group: "Flashcards",
      stats: [
        {
          order: 1,
          title: "Moved",
          description:
            "The total number of questions moved into the Easy, Normal or Hard deck",
          value: progress.Flashcards.moved,
          format: "number",
        },
        {
          order: 2,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.Flashcards.dayStreak,
          format: "number",
        },
        {
          order: 3,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.Flashcards.touched,
          format: "date",
        },
      ],
    },
    studyguide: {
      group: "StudyGuide",
      stats: [
        {
          order: 1,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.StudyGuide.touched,
          format: "date",
        },
      ],
    },
    multiplechoice: {
      group: "MultipleChoice",
      stats: [
        {
          order: 1,
          title: "Moved",
          description:
            "The total number of questions moved into the Easy, Normal or Hard deck",
          value: progress.MultipleChoice.moved,
          format: "number",
        },
        {
          order: 2,
          title: "Answered",
          description: "The total number of questions answered",
          value: progress.MultipleChoice.answered,
          format: "number",
        },
        {
          order: 3,
          title: "Correct",
          description: "The total number of questions answered correctly",
          value: progress.MultipleChoice.Right,
          format: "number",
        },
        {
          order: 4,
          title: "Wrong",
          description: "The total number of questions answered incorrectly",
          value: progress.MultipleChoice.Wrong,
          format: "number",
        },
        {
          order: 5,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.MultipleChoice.dayStreak,
          format: "number",
        },
        {
          order: 6,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.MultipleChoice.touched,
          format: "date",
        },
      ],
    },
    audioguide: {
      group: "AudioGuide",
      stats: [
        {
          order: 1,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.AudioGuide.touched,
          format: "date",
        },
      ],
    },
    survival: {
      group: "Survival",
      stats: [
        {
          order: 1,
          title: "Answered",
          description: "The total number of questions answered",
          value: progress.Survival.answered,
          format: "number",
        },
        {
          order: 2,
          title: "Correct",
          description: "The total number of questions answered correctly",
          value: progress.Survival.Right,
          format: "number",
        },
        {
          order: 3,
          title: "Wrong",
          description: "The total number of questions answered incorrectly",
          value: progress.Survival.Wrong,
          format: "number",
        },
        {
          order: 4,
          title: "Win Streak",
          description: "Your current Survival winning streak.",
          value: progress.Survival.WinStreak,
          format: "number",
        },
        {
          order: 5,
          title: "Losing Streak",
          description: "Your current Survival losing streak.",
          value: progress.Survival.LoseStreak,
          format: "number",
        },
        {
          order: 6,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.Survival.dayStreak,
          format: "number",
        },
        {
          order: 7,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.Survival.touched,
          format: "date",
        },
        {
          order: 8,
          title: "Last Score",
          description: "Your score from the last time you played",
          value: progress.Survival.lastScore,
          format: "survival",
        },
        {
          order: 9,
          title: "Best Score",
          description: "Your best score",
          value: progress.Survival.bestScore,
          format: "survival",
        },
      ],
    },
    game: {
      group: "Game",
      stats: [
        {
          order: 1,
          title: "Answered",
          description: "The total number of questions answered",
          value: progress.Game.answered,
          format: "number",
        },
        {
          order: 2,
          title: "Correct",
          description: "The total number of questions answered correctly",
          value: progress.Game.Right,
          format: "number",
        },
        {
          order: 3,
          title: "Wrong",
          description: "The total number of questions answered incorrectly",
          value: progress.Game.Wrong,
          format: "number",
        },
        {
          order: 4,
          title: "Win Streak",
          description: "Your current Game winning streak.",
          value: progress.Game.WinStreak,
          format: "number",
        },
        {
          order: 5,
          title: "Losing Streak",
          description: "Your current Game losing streak.",
          value: progress.Game.LoseStreak,
          format: "number",
        },
        {
          order: 6,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.Game.dayStreak,
          format: "number",
        },
        {
          order: 7,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.Game.touched,
          format: "date",
        },
        {
          order: 8,
          title: "Last Score",
          description: "Your score from the last time you played",
          value: progress.Game.lastScore,
          format: "game",
        },
        {
          order: 9,
          title: "Best Score",
          description: "Your best score",
          value: progress.Game.bestScore,
          format: "game",
        },
      ],
    },
    test: {
      group: "Test",
      stats: [
        {
          order: 1,
          title: "Answered",
          description: "The total number of questions answered",
          value: progress.Test.answered,
          format: "number",
        },
        {
          order: 2,
          title: "Correct",
          description: "The total number of questions answered correctly",
          value: progress.Test.Right,
          format: "number",
        },
        {
          order: 3,
          title: "Wrong",
          description: "The total number of questions answered incorrectly",
          value: progress.Test.Wrong,
          format: "number",
        },
        {
          order: 6,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.Test.dayStreak,
          format: "number",
        },
        {
          order: 7,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.Test.touched,
          format: "date",
        },
        {
          order: 8,
          title: "Last Score",
          description: "Your score from the last time you played",
          value: progress.Test.lastScore,
          format: "test",
        },
        {
          order: 9,
          title: "Best Score",
          description: "Your best score",
          value: progress.Test.bestScore,
          format: "test",
        },
      ],
    },
    mockexam: {
      group: "MockExam",
      stats: [
        {
          order: 1,
          title: "Answered",
          description: "The total number of questions answered",
          value: progress.MockExam.answered,
          format: "number",
        },
        {
          order: 2,
          title: "Correct",
          description: "The total number of questions answered correctly",
          value: progress.MockExam.Right,
          format: "number",
        },
        {
          order: 3,
          title: "Wrong",
          description: "The total number of questions answered incorrectly",
          value: progress.MockExam.Wrong,
          format: "number",
        },
        {
          order: 6,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.MockExam.dayStreak,
          format: "number",
        },
        {
          order: 7,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.MockExam.touched,
          format: "date",
        },
        {
          order: 8,
          title: "Last Score",
          description: "Your score from the last time you played",
          value: progress.MockExam.lastScore,
          format: "test",
        },
        {
          order: 9,
          title: "Best Score",
          description: "Your best score",
          value: progress.MockExam.bestScore,
          format: "test",
        },
      ],
    },
    general: {
      group: "General",
      stats: [
        {
          order: 1,
          title: "Answered",
          description:
            "The total number of questions answered in all study modes",
          value: progress.global.answered,
          format: "number",
        },
        {
          order: 2,
          title: "Correct",
          description:
            "The total number of questions answered correctly in all study modes",
          value: progress.global.Right,
          format: "number",
        },
        {
          order: 3,
          title: "Wrong",
          description:
            "The total number of questions answered incorrectly in all study modes",
          value: progress.global.Wrong,
          format: "number",
        },
        {
          order: 4,
          title: "Moved",
          description:
            "The total number of questions moved into the Easy, Normal or Hard deck",
          value: progress.global.moved,
          format: "number",
        },
        {
          order: 5,
          title: "Day Study Streak",
          description: "Your current study streak in days",
          value: progress.global.dayStreak,
          format: "number",
        },
        {
          order: 6,
          title: "Last Played",
          description: "They date/time you last played",
          value: progress.global.touched,
          format: "date",
        },
      ],
    },
  };
};
