import { Box } from "@mui/material";
import React from "react";
import { totalsToStats } from "utils";
import { green, red, lightGreen, blue } from "@mui/material/colors";

const DeckProgress = ({ totals }) => {
  const stats = totalsToStats(totals);
  const total = stats.easy + stats.normal + stats.hard + stats.new;

  // Check for zero total to avoid division by zero
  if (total === 0) {
    <Box sx={{ display: "flex", height: "2px" }}>
      <Box sx={{ width: `100%`, backgroundColor: blue[200] }} />
    </Box>;
  }

  // Calculate the widths for each section
  const easyWidth = (stats.easy / total) * 100;
  const normalWidth = ((stats.normal + stats.new) / total) * 100;
  const hardWidth = (stats.hard / total) * 100;

  // Check for NaN or Infinity values and replace them with zeros
  const safeEasyWidth =
    isNaN(easyWidth) || !isFinite(easyWidth) ? 0 : easyWidth;
  const safeNormalWidth =
    isNaN(normalWidth) || !isFinite(normalWidth) ? 0 : normalWidth;
  const safeHardWidth =
    isNaN(hardWidth) || !isFinite(hardWidth) ? 0 : hardWidth;

  return (
    <Box sx={{ display: "flex", height: "3px" }}>
      <Box sx={{ width: `${safeEasyWidth}%`, backgroundColor: green[600] }} />
      <Box sx={{ width: `${safeNormalWidth}%`, backgroundColor: blue[100] }} />
      <Box sx={{ width: `${safeHardWidth}%`, backgroundColor: red[600] }} />
    </Box>
  );
};

export default DeckProgress;
