import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAuth } from "providers/ProvideAuth";
import Typography from "@mui/material/Typography";
import * as routes from "routes";
import AvatarHeader from "components/AvatarHeader";
import { Box } from "@mui/system";
import Copyright from "components/Copyright";
import { TextField, Link, Button, Form } from "components/Forms";
import {
  RowContainer,
  ColumnContainer,
  FieldContainer,
  Page,
} from "advancement-solutions-components/dist/components";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export default function Component() {
  const location = useLocation();
  const { login, error, init } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values, location);
    },
  });

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Box
        bordered={true}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ColumnContainer>
          <AvatarHeader title="Sign in" />
          <Form noValidate onSubmit={formik.handleSubmit}>
            <FieldContainer>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              {error && (
                <Typography variant="subtitle2" color="error">
                  {error.message}
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign In
              </Button>
              <RowContainer>
                <Link align="center" to={routes.REQUEST_RESET}>
                  Forgot password?
                </Link>
                <Link align="center" to={routes.REGISTRATION}>
                  Register
                </Link>
                <Link align="center" to={routes.REQUEST_OTP}>
                  Sign in with link
                </Link>
              </RowContainer>
            </FieldContainer>
            <Box mt={5}>
              <Copyright />
            </Box>
          </Form>
        </ColumnContainer>
      </Box>
    </Page>
  );
}
