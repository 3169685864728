import React from "react";
import { Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/system";

const NoContentWarning = ({ warning }) => {
  return (
    <Box
      sx={{
        margin: 2,
        padding: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WarningIcon
        sx={{
          margin: 2,
          fontSize: 40,
          color: "info.main",
        }}
      />
      <Typography variant="h6" color="textSecondary" align="center">
        {warning || "Looks like we don't have anything. Check back later."}
      </Typography>
    </Box>
  );
};

export default NoContentWarning;
