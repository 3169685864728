import React from "react";
import { ListSubheader as MuiListSubHeader } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";

const ListSubheader = ({ text, sx, wide }) => {
  return (
    <Box sx={{ ...sx, ...(wide && { width: "100%" }) }}>
      <MuiListSubHeader
        component="div"
        id="sub-header"
        sx={{
          backgroundColor: "tertiary.main",
          color: grey[700],
        }}
      >
        {text}
      </MuiListSubHeader>
    </Box>
  );
};
export default ListSubheader;
