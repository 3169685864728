import { Fragment } from "react";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import MenuAppBar from "components/MenuAppBar";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import TopicsPage from "pages/TopicsPage";
import TopicPage from "pages/TopicPage";
import ChapterPage from "pages/ChapterPage";
import NoMatch from "pages/NoMatch";

export const NOT_FOUND = "/not-found";
export const LOGIN = "/login";
export const HANDLE_OTP = "/otp/:code";
export const HANDLE_RESET = "/reset-password/:code";
export const HOME = "/";
export const REGISTRATION = "/registration";
export const FOCUSED_QUESTIONS = "/questions/:focus";
export const FOCUSED_FLASHCARDS = "/questions/:focus/flashcards";
export const FOCUSED_TEST = "/questions/:focus/test";
export const FOCUSED_TEST_GRADED = "/questions/:focus/test/graded";
export const FOCUSED_GAME = "/questions/:focus/game";
export const FOCUSED_SURVIVAL = "/questions/:focus/survival";
export const FOCUSED_STUDY_GUIDE = "/questions/:focus/study-guide";
export const FOCUSED_MULTIPLE_CHOICE = "/questions/:focus/multiple-choice";
export const FOCUSED_BROWSE = "/questions/:focus/browse";
export const FOCUSED_LEARN_MORE =
  "/questions/:focus/:mode/learn-more/:questionId";
export const REQUEST_OTP = "/request-otp";
export const REQUEST_RESET = "/request-reset";
export const TOPICS = "/:group/topics";
export const TOPIC = "/:group/topics/:topicId";
export const CHAPTER = "/:group/topics/:topicId/chapters/:chapterId";
export const FLASHCARDS =
  "/:group/topics/:topicId/chapters/:chapterId/flashcards";
export const STUDY_GUIDE =
  "/:group/topics/:topicId/chapters/:chapterId/study-guide";
export const MULTIPLE_CHOICE =
  "/:group/topics/:topicId/chapters/:chapterId/multiple-choice";
export const AUDIO_GUIDE =
  "/:group/topics/:topicId/chapters/:chapterId/audio-guide";
export const GAME = "/:group/topics/:topicId/chapters/:chapterId/game";
export const SURVIVAL = "/:group/topics/:topicId/chapters/:chapterId/survival";
export const TEST = "/:group/topics/:topicId/chapters/:chapterId/test";
export const TEST_GRADED =
  "/:group/topics/:topicId/chapters/:chapterId/test/graded";
export const BROWSE = "/:group/topics/:topicId/chapters/:chapterId/browse";
export const LEARN_MORE =
  "/:group/topics/:topicId/chapters/:chapterId/:mode/learn-more/:questionId";
export const PAYMENT = "/payment";
export const MOCK_EXAM = "/mock-exam";
export const MOCK_EXAM_GRADED = "/mock-exam/graded";
export const USER_DETAILS = "/profile/details";
export const CHANGE_PASSWORD = "/profile/change-password";
export const PROGRESS = "/profile/progress";
export const SUBSCRIPTION = "/profile/subscription";
export const CAUSE_ERROR = "/errors/:code";
export const LIST_ERRORS = "/errors";

const FocusedQuestionsPage = lazy(() => import("pages/FocusedQuestionsPage"));
const FocusedFlashcardsPage = lazy(() =>
  import("pages/FocusedQuestions/FlashcardsPage")
);
const FocusedStudyGuidePage = lazy(() =>
  import("pages/FocusedQuestions/StudyGuidePage")
);
const FocusedGamePage = lazy(() => import("pages/FocusedQuestions/GamePage"));
const FocusedSurvivalPage = lazy(() =>
  import("pages/FocusedQuestions/SurvivalPage")
);
const FocusedStudyMultipleChoicePage = lazy(() =>
  import("pages/FocusedQuestions/MultipleChoicePage")
);
const FocusedBrowsePage = lazy(() =>
  import("pages/FocusedQuestions/BrowsePage")
);
const FocusedTestPage = lazy(() => import("pages/FocusedQuestions/TestPage"));
const FocusedTestGradedPage = lazy(() =>
  import("pages/FocusedQuestions/TestGradedPage")
);

const MockExamPage = lazy(() => import("pages/MockExamPage"));
const MockExamGradedPage = lazy(() => import("pages/MockExamGradedPage"));
const FlashcardsPage = lazy(() => import("pages/Questions/FlashcardsPage"));
const StudyGuidePage = lazy(() => import("pages/Questions/StudyGuidePage"));
const MultipleChoicePage = lazy(() =>
  import("pages/Questions/MultipleChoicePage")
);
const AudioGuidePage = lazy(() => import("pages/Questions/AudioGuidePage"));
const GamePage = lazy(() => import("pages/Questions/GamePage"));
const SurvivalPage = lazy(() => import("pages/Questions/SurvivalPage"));
const TestPage = lazy(() => import("pages/Questions/TestPage"));
const TestGradedPage = lazy(() => import("pages/Questions/TestGradedPage"));
const BrowsePage = lazy(() => import("pages/Questions/BrowsePage"));
const PaymentPage = lazy(() => import("pages/PaymentPage"));
const DetailsPage = lazy(() => import("pages/Profile/DetailsPage"));
const ChangePasswordPage = lazy(() =>
  import("pages/Profile/ChangePasswordPage")
);
const ProgressPage = lazy(() => import("pages/Profile/ProgressPage"));
const SubscriptionPage = lazy(() => import("pages/Profile/SubscriptionPage"));
const RegistrationPage = lazy(() => import("pages/RegistrationPage"));
const RequestOtpPage = lazy(() => import("pages/RequestOtpPage"));
const HandleOtpPage = lazy(() => import("pages/HandleOtpPage"));
const RequestResetPage = lazy(() => import("pages/RequestResetPage"));
const HandleResetPage = lazy(() => import("pages/HandleResetPage"));

const CauseErrorPage = lazy(() => import("pages/CauseErrorPage"));
const ListErrorsPage = lazy(() => import("pages/ListErrorsPage"));

export const routes = [
  {
    title: "Login",
    path: LOGIN,
    menuBar: null,
    component: LoginPage,
    exact: true,
    private: false,
  },
  {
    title: "Reset Password",
    path: HANDLE_RESET,
    menuBar: null,
    component: HandleResetPage,
    exact: true,
    private: false,
  },
  {
    title: "One Time Password",
    path: HANDLE_OTP,
    menuBar: null,
    component: HandleOtpPage,
    exact: true,
    private: false,
  },
  {
    title: "Reset Password",
    path: REQUEST_RESET,
    menuBar: null,
    component: RequestResetPage,
    exact: true,
    private: false,
  },
  {
    title: "Topics",
    path: TOPICS,
    menuBar: MenuAppBar,
    component: TopicsPage,
    exact: true,
    private: true,
  },
  {
    title: "Chapters",
    path: TOPIC,
    menuBar: MenuAppBar,
    component: TopicPage,
    exact: true,
    private: true,
  },
  {
    title: "Modes",
    path: CHAPTER,
    menuBar: MenuAppBar,
    component: ChapterPage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Questions",
    path: FOCUSED_QUESTIONS,
    menuBar: MenuAppBar,
    component: FocusedQuestionsPage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Study Guide",
    path: FOCUSED_STUDY_GUIDE,
    menuBar: MenuAppBar,
    component: FocusedStudyGuidePage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Multiple Choice",
    path: FOCUSED_MULTIPLE_CHOICE,
    menuBar: MenuAppBar,
    component: FocusedStudyMultipleChoicePage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Flashcards",
    path: FOCUSED_FLASHCARDS,
    menuBar: MenuAppBar,
    component: FocusedFlashcardsPage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Game",
    path: FOCUSED_GAME,
    menuBar: MenuAppBar,
    component: FocusedGamePage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Survival",
    path: FOCUSED_SURVIVAL,
    menuBar: MenuAppBar,
    component: FocusedSurvivalPage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Test",
    path: FOCUSED_TEST,
    menuBar: MenuAppBar,
    component: FocusedTestPage,
    exact: true,
    private: true,
  },
  {
    title: "Focus Graded Test",
    path: FOCUSED_TEST_GRADED,
    menuBar: MenuAppBar,
    component: FocusedTestGradedPage,
    exact: true,
    private: true,
  },
  {
    title: "Focused Browse",
    path: FOCUSED_BROWSE,
    menuBar: MenuAppBar,
    component: FocusedBrowsePage,
    exact: true,
    private: true,
  },
  {
    title: "Mock Exam",
    path: MOCK_EXAM,
    menuBar: MenuAppBar,
    component: MockExamPage,
    exact: true,
    private: true,
  },
  {
    title: "Graded Exam",
    path: MOCK_EXAM_GRADED,
    menuBar: MenuAppBar,
    component: MockExamGradedPage,
    exact: true,
    private: true,
  },
  {
    title: "Flashcards",
    path: FLASHCARDS,
    menuBar: MenuAppBar,
    component: FlashcardsPage,
    exact: true,
    private: true,
  },
  {
    title: "Study Guide",
    path: STUDY_GUIDE,
    menuBar: MenuAppBar,
    component: StudyGuidePage,
    exact: true,
    private: true,
  },
  {
    title: "Multiple Choice",
    path: MULTIPLE_CHOICE,
    menuBar: MenuAppBar,
    component: MultipleChoicePage,
    exact: true,
    private: true,
  },
  {
    title: "Audio Guide",
    path: AUDIO_GUIDE,
    menuBar: MenuAppBar,
    component: AudioGuidePage,
    exact: true,
    private: true,
  },
  {
    title: "Game",
    path: GAME,
    menuBar: MenuAppBar,
    component: GamePage,
    exact: true,
    private: true,
  },
  {
    title: "Survival",
    path: SURVIVAL,
    menuBar: MenuAppBar,
    component: SurvivalPage,
    exact: true,
    private: true,
  },
  {
    title: "Test",
    path: TEST,
    menuBar: MenuAppBar,
    component: TestPage,
    exact: true,
    private: true,
  },
  {
    title: "Graded Test",
    path: TEST_GRADED,
    menuBar: MenuAppBar,
    component: TestGradedPage,
    exact: true,
    private: true,
  },
  {
    title: "Browse",
    path: BROWSE,
    menuBar: MenuAppBar,
    component: BrowsePage,
    exact: true,
    private: true,
  },
  {
    title: "Payment",
    path: PAYMENT,
    menuBar: MenuAppBar,
    component: PaymentPage,
    exact: false,
    private: true,
  },
  {
    title: "User Details",
    path: USER_DETAILS,
    menuBar: MenuAppBar,
    component: DetailsPage,
    exact: false,
    private: true,
  },
  {
    title: "Change Password",
    path: CHANGE_PASSWORD,
    menuBar: MenuAppBar,
    component: ChangePasswordPage,
    exact: false,
    private: true,
  },
  {
    title: "Progress",
    path: PROGRESS,
    menuBar: MenuAppBar,
    component: ProgressPage,
    exact: false,
    private: true,
  },
  {
    title: "Subscription",
    path: SUBSCRIPTION,
    menuBar: MenuAppBar,
    component: SubscriptionPage,
    exact: false,
    private: true,
  },
  {
    title: "Home",
    path: HOME,
    menuBar: MenuAppBar,
    component: HomePage,
    exact: true,
    private: true,
  },
  {
    title: "Registration",
    path: REGISTRATION,
    menuBar: null,
    component: RegistrationPage,
    exact: true,
    private: false,
  },
  {
    title: "One Time Password",
    path: REQUEST_OTP,
    menuBar: null,
    component: RequestOtpPage,
    exact: true,
    private: false,
  },
  {
    title: "Cause Error",
    path: CAUSE_ERROR,
    menuBar: null,
    component: CauseErrorPage,
    exact: true,
    private: false,
  },
  {
    title: "List Errors",
    path: LIST_ERRORS,
    menuBar: null,
    component: ListErrorsPage,
    exact: true,
    private: false,
  },
];

export const Routes = () => {
  return (
    <Switch>
      {routes.map((route) =>
        route.private ? (
          <PrivateRoute key={route.path} exact={route.exact} path={route.path}>
            {({ user }) => (
              <Fragment>
                {route.menuBar && <route.menuBar />}
                <route.component user={user} />
              </Fragment>
            )}
          </PrivateRoute>
        ) : (
          <Route key={route.path} exact={route.exact} path={route.path}>
            <route.component />
          </Route>
        )
      )}
      <Route component={NoMatch} />
    </Switch>
  );
};
