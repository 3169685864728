import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { Link } from "react-router-dom";

const Mode = ({ rootUrl, mode }) => {
  return (
    <React.Fragment key={mode.id}>
      <Divider />
      <ListItem button component={Link} to={`${rootUrl}/${mode.id}`}>
        <ListItemIcon>
          <mode.icon />
        </ListItemIcon>
        <ListItemText primary={mode.title} />
      </ListItem>
    </React.Fragment>
  );
};

export default Mode;
