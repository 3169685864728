import React from "react";
import List from "@mui/material/List";
import Topic from "./Topic";
import ListSubheader from "components/ListSubheader";

const Topics = ({ group, topics }) => {
  return (
    <List subheader={<ListSubheader text="Topics" />}>
      {topics.map((topic) => (
        <Topic key={topic.id} group={group} topic={topic} />
      ))}
    </List>
  );
};

export default Topics;
