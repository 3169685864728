export const achievementIds = [
  "rs0005",
  "rs0010",
  "rs0025",
  "rs0050",
  "rs0100",
  "rs0150",
  "rs0200",
  "rs0250",
  "rs0300",
  "ws0005",
  "ws0010",
  "ws0025",
  "ws0050",
  "ws0100",
  "ws0150",
  "ws0200",
  "ws0250",
  "ws0300",
  "es0005",
  "es0010",
  "es0025",
  "es0050",
  "es0100",
  "es0150",
  "es0200",
  "es0250",
  "es0300",
  "hs0005",
  "hs0010",
  "hs0025",
  "hs0050",
  "hs0100",
  "hs0150",
  "hs0200",
  "hs0250",
  "hs0300",
  "ds0001",
  "ds0007",
  "ds0014",
  "ds0030",
  "ds0060",
  "ds0090",
  "ds0120",
  "ds0180",
  "ds0365",
];
export const achievements = {
  // Right Streak
  rs0005: {
    title: "Correct Answer Streak",
    description: "Answer 5 questions, correctly, in a row.",
    level: 1,
    order: 1,
    status: "hidden",
    unlockedAt: null,
  },
  rs0010: {
    title: "Correct Answer Streak",
    description: "Answer 10 questions, correctly, in a row.",
    level: 2,
    order: 2,
    status: "hidden",
    unlockedAt: null,
  },
  rs0025: {
    title: "Correct Answer Streak",
    description: "Answer 25 questions, correctly, in a row.",
    level: 3,
    order: 3,
    status: "hidden",
    unlockedAt: null,
  },
  rs0050: {
    title: "Correct Answer Streak",
    description: "Answer 50 questions, correctly, in a row.",
    level: 4,
    order: 4,
    status: "hidden",
    unlockedAt: null,
  },
  rs0100: {
    title: "Correct Answer Streak",
    description: "Answer 100 questions, correctly, in a row.",
    level: 5,
    order: 5,
    status: "hidden",
    unlockedAt: null,
  },
  rs0150: {
    title: "Correct Answer Streak",
    description: "Answer 150 questions, correctly, in a row.",
    level: 6,
    order: 6,
    status: "hidden",
    unlockedAt: null,
  },
  rs0200: {
    title: "Correct Answer Streak",
    description: "Answer 200 questions, correctly, in a row.",
    level: 7,
    order: 7,
    status: "hidden",
    unlockedAt: null,
  },
  rs0250: {
    title: "Correct Answer Streak",
    description: "Answer 250 questions, correctly, in a row.",
    level: 8,
    order: 8,
    status: "hidden",
    unlockedAt: null,
  },
  rs0300: {
    title: "Correct Answer Streak",
    description: "Answer 300 questions, correctly, in a row.",
    level: 9,
    order: 9,
    status: "hidden",
    unlockedAt: null,
  },
  // Wrong Streak
  ws0005: {
    title: "Incorrect Answer Streak",
    description: "Answer 5 questions, incorrectly, in a row.",
    level: 1,
    order: 10,
    status: "hidden",
    unlockedAt: null,
  },
  ws0010: {
    title: "Incorrect Answer Streak",
    description: "Answer 10 questions, incorrectly, in a row.",
    level: 2,
    order: 11,
    status: "hidden",
    unlockedAt: null,
  },
  ws0025: {
    title: "Incorrect Answer Streak",
    description: "Answer 25 questions, incorrectly, in a row.",
    level: 3,
    order: 12,
    status: "hidden",
    unlockedAt: null,
  },
  ws0050: {
    title: "Incorrect Answer Streak",
    description: "Answer 50 questions, incorrectly, in a row.",
    level: 4,
    order: 13,
    status: "hidden",
    unlockedAt: null,
  },
  ws0100: {
    title: "Incorrect Answer Streak",
    description: "Answer 100 questions, incorrectly, in a row.",
    level: 5,
    order: 14,
    status: "hidden",
    unlockedAt: null,
  },
  ws0150: {
    title: "Incorrect Answer Streak",
    description: "Answer 150 questions, incorrectly, in a row.",
    level: 6,
    order: 15,
    status: "hidden",
    unlockedAt: null,
  },
  ws0200: {
    title: "Incorrect Answer Streak",
    description: "Answer 200 questions, incorrectly, in a row.",
    level: 7,
    order: 16,
    status: "hidden",
    unlockedAt: null,
  },
  ws0250: {
    title: "Incorrect Answer Streak",
    description: "Answer 250 questions, incorrectly, in a row.",
    level: 8,
    order: 17,
    status: "hidden",
    unlockedAt: null,
  },
  ws0300: {
    title: "Incorrect Answer Streak",
    description: "Answer 300 questions, incorrectly, in a row.",
    level: 9,
    order: 18,
    status: "hidden",
    unlockedAt: null,
  },
  // Easy Streak
  es0005: {
    title: "Easy Deck Streak",
    description: "Move 5 questions, into the Easy deck, in a row.",
    level: 1,
    order: 19,
    status: "hidden",
    unlockedAt: null,
  },
  es0010: {
    title: "Easy Deck Streak",
    description: "Move 10 questions, into the Easy deck, in a row.",
    level: 2,
    order: 20,
    status: "hidden",
    unlockedAt: null,
  },
  es0025: {
    title: "Easy Deck Streak",
    description: "Move 25 questions, into the Easy deck, in a row.",
    level: 3,
    order: 21,
    status: "hidden",
    unlockedAt: null,
  },
  es0050: {
    title: "Easy Deck Streak",
    description: "Move 50 questions, into the Easy deck, in a row.",
    level: 4,
    order: 22,
    status: "hidden",
    unlockedAt: null,
  },
  es0100: {
    title: "Easy Deck Streak",
    description: "Move 100 questions, into the Easy deck, in a row.",
    level: 5,
    order: 23,
    status: "hidden",
    unlockedAt: null,
  },
  es0150: {
    title: "Easy Deck Streak",
    description: "Move 150 questions, into the Easy deck, in a row.",
    level: 6,
    order: 24,
    status: "hidden",
    unlockedAt: null,
  },
  es0200: {
    title: "Easy Deck Streak",
    description: "Move 200 questions, into the Easy deck, in a row.",
    level: 7,
    order: 25,
    status: "hidden",
    unlockedAt: null,
  },
  es0250: {
    title: "Easy Deck Streak",
    description: "Move 250 questions, into the Easy deck, in a row.",
    level: 8,
    order: 26,
    status: "hidden",
    unlockedAt: null,
  },
  es0300: {
    title: "Easy Deck Streak",
    description: "Move 300 questions, into the Easy deck, in a row.",
    level: 9,
    order: 27,
    status: "hidden",
    unlockedAt: null,
  },
  // Hard Streak
  hs0005: {
    title: "Hard Deck Streak",
    description: "Move 5 questions, into the Hard deck, in a row.",
    level: 1,
    order: 28,
    status: "hidden",
    unlockedAt: null,
  },
  hs0010: {
    title: "Hard Deck Streak",
    description: "Move 10 questions, into the Hard deck, in a row.",
    level: 2,
    order: 29,
    status: "hidden",
    unlockedAt: null,
  },
  hs0025: {
    title: "Hard Deck Streak",
    description: "Move 25 questions, into the Hard deck, in a row.",
    level: 3,
    order: 30,
    status: "hidden",
    unlockedAt: null,
  },
  hs0050: {
    title: "Hard Deck Streak",
    description: "Move 50 questions, into the Hard deck, in a row.",
    level: 4,
    order: 31,
    status: "hidden",
    unlockedAt: null,
  },
  hs0100: {
    title: "Hard Deck Streak",
    description: "Move 100 questions, into the Hard deck, in a row.",
    level: 5,
    order: 32,
    status: "hidden",
    unlockedAt: null,
  },
  hs0150: {
    title: "Hard Deck Streak",
    description: "Move 150 questions, into the Hard deck, in a row.",
    level: 6,
    order: 33,
    status: "hidden",
    unlockedAt: null,
  },
  hs0200: {
    title: "Hard Deck Streak",
    description: "Move 200 questions, into the Hard deck, in a row.",
    level: 7,
    order: 34,
    status: "hidden",
    unlockedAt: null,
  },
  hs0250: {
    title: "Hard Deck Streak",
    description: "Move 250 questions, into the Hard deck, in a row.",
    level: 8,
    order: 35,
    status: "hidden",
    unlockedAt: null,
  },
  hs0300: {
    title: "Hard Deck Streak",
    description: "Move 300 questions, into the Hard deck, in a row.",
    level: 9,
    order: 36,
    status: "hidden",
    unlockedAt: null,
  },
  // Study Streak
  ds0001: {
    title: "Study Streak",
    description: "You studied 1 day",
    level: 1,
    order: 37,
    status: "hidden",
    unlockedAt: null,
  },
  ds0007: {
    title: "Study Streak",
    description: "One full week of consistent studying!",
    level: 2,
    order: 38,
    status: "hidden",
    unlockedAt: null,
  },
  ds0014: {
    title: "Study Streak",
    description: "Two full weeks of consistent studying!",
    level: 3,
    order: 39,
    status: "hidden",
    unlockedAt: null,
  },
  ds0030: {
    title: "Study Streak",
    description: "30 day studying streak!",
    level: 4,
    order: 40,
    status: "hidden",
    unlockedAt: null,
  },
  ds0060: {
    title: "Study Streak",
    description: "60 day studying streak!",
    level: 5,
    order: 41,
    status: "hidden",
    unlockedAt: null,
  },
  ds0090: {
    title: "Study Streak",
    description: "90 day studying streak!",
    level: 6,
    order: 42,
    status: "hidden",
    unlockedAt: null,
  },
  ds0120: {
    title: "Study Streak",
    description: "120 day studying streak!",
    level: 7,
    order: 43,
    status: "hidden",
    unlockedAt: null,
  },
  ds0180: {
    title: "Study Streak",
    description: "180 day studying streak!",
    level: 8,
    order: 44,
    status: "hidden",
    unlockedAt: null,
  },
  ds0365: {
    title: "Study Streak",
    description: "Studied every day for an entire year!",
    level: 9,
    order: 45,
    status: "hidden",
    unlockedAt: null,
  },
};
