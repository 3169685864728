import { actions } from ".";
import { processInteraction, tryMerge } from "./mutations";

export const reducer = (send) => (state, action) => {
  //console.log("reducer", action.type, action.payload, state);

  switch (action.type) {
    case actions.interaction:
      return processInteraction(state, action, send);
    case actions.load:
      return { ...state, loading: true };
    case actions.success:
      return { ...state, ...tryMerge(action.payload), loading: false };
    case actions.failure:
      return {
        ...state,
        error: { message: "Failed to load user progress" },
        loading: false,
      };
    default:
      return state;
  }
};
