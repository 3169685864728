export const underConstruction = {
  border: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: "warning.main",
  },
  detail: {
    color: "warning.main",
  },
  icon: {
    marginRight: "2px",
    color: "warning.main",
  },
  expandMoreIcon: {
    color: "warning.main",
  },
};

export const cannotLocate = {
  border: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: "success.dark",
  },

  detail: {
    color: "success.dark",
  },

  icon: {
    marginRight: "2px",
    color: "success.dark",
  },

  expandMoreIcon: {
    color: "success.dark",
  },
};

export const copyrightdMaterial = {
  border: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: "error.main",
  },
  detail: {
    color: "error.main",
  },
  icon: {
    marginRight: "2px",
    color: "error.main",
  },

  expandMoreIcon: {
    color: "error.main",
  },
};

export const restrictedDistribution = {
  border: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: "error.main",
  },
  detail: {
    color: "error.main",
  },
  icon: {
    marginRight: "2px",
    color: "error.main",
  },
  expandMoreIcon: {
    color: "error.main",
  },
};

export const unrestricted = {
  border: {
    borderLeftStyle: "solid",
    borderLeftWidth: "2px",
    borderLeftColor: "transparent",
  },
  icon: {
    marginRight: "2px",
  },
};
