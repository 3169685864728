import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs } from "advancement-solutions-components/dist/components";
import { Breadcrumb } from "advancement-solutions-components/dist/components";

const Crumbs = () => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" maxItems={5}>
      <Breadcrumb label="Home" to={`/`} icon={<HomeIcon fontSize="small" />} />
      <Breadcrumb disabled label="Topics" />
    </Breadcrumbs>
  );
};

export default Crumbs;
