import React, { useEffect } from "react";
import { useChapterRating } from "hooks/api";
import MyRating from "./MyRating";

const ChapterRating = ({ topicId, chapterId }) => {
  const [getRating, setRating, rating] = useChapterRating();

  useEffect(() => {
    async function fetchRating() {
      await getRating(topicId, chapterId);
    }
    fetchRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MyRating
      rating={rating}
      onChange={(event, newValue) => {
        setRating(topicId, chapterId, newValue || 0);
      }}
    />
  );
};

export default ChapterRating;
