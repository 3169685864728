import axios from "axios";
import * as routes from "routes";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // this tells axios to send cookies (tokens inside)
});
export const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // this tells axios to send cookies (tokens inside)
});

export const retryInterceptor = (api, location, history, send) => {
  return (err) => {
    //TokenExpiredError
    if ([4014].includes(err.response.data.error.code)) {
      // Our token has expired. Let's see if we can refresh it.
      return api
        .post("/public/auth/refresh")
        .then((res) => {
          // if all goes well we retry the failed request
          return api.request(err.config);
        })
        .catch(() => {
          // api has no interceptors so we catch any errors
          // getting a new token and send the user to the login
          send({
            message: `Your session has expired.`,
            severity: "info",
          });
          if (location.pathname !== "/login") {
            history.push({
              pathname: routes.LOGIN,
              state: { from: location },
            });
          }
        });
    }

    return Promise.reject(err);
  };
};

export const passThruInterceptor = (send) => {
  return (res) => {
    return res;
  };
};

export const errorInterceptor = (location, history, send) => {
  return (err) => {
    // error interceptor is the last interceptor
    // if no has handled the error then this guy
    // will take a look and see if it's interested or if
    // not it will just pass it a long

    //TokenExpiredError
    //RefreshTokenExpiredError
    if ([4014, 4015].includes(err.response.data.error.code)) {
      send({
        message: `Your session has expired.`,
        severity: "info",
      });
      if (location.pathname !== "/login") {
        history.push({
          pathname: routes.LOGIN,
          state: { from: location },
        });
      }
    }

    //TokenError
    //TokenMissingError
    //TokenRevokedError
    //TokenMismatchError
    //TokenVoidError
    if ([4010, 4011, 4012, 4013, 4016].includes(err.response.data.error.code)) {
      console.log(location.pathname);
      if (
        ["reset-password", "request-reset", "registration"].some((str) =>
          location.pathname.includes(str)
        )
      ) {
        return;
      }
      send({
        message: `You must be logged in to view this content.`,
        severity: "info",
      });
      if (location.pathname !== "/login") {
        history.push({
          pathname: routes.LOGIN,
          state: { from: location },
        });
      }
    }

    //Service unavailable
    if ([503].includes(err.response.status)) {
      const apiMessage =
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.message
          ? err.response.data.error.message
          : null;
      send({
        message:
          apiMessage ||
          `We are currently doing server maintence. We'll be back online shortly.`,
        severity: "info",
      });
      if (location.pathname !== "/login") {
        history.push({
          pathname: routes.LOGIN,
          state: { from: location },
        });
      }
    }

    if ([429].includes(err.response.status)) {
      send({
        message: `Too many requests.`,
        severity: "info",
      });
      return Promise.reject(err);
    }

    if ([402].includes(err.response.status)) {
      return Promise.reject(err);
    }

    console.log(err);
    return Promise.reject(err);
  };
};

export const missingPaymentInterceptor = (history, send) => {
  return (err) => {
    if ([4020].includes(err.response.data.error.code)) {
      send({
        message: `Payment required.`,
        severity: "info",
      });
      history.push(routes.PAYMENT);
    } else {
      return Promise.reject(err);
    }
  };
};
