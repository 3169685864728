import React from "react";
import { Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";

const MyToolbar = ({ title, children }) => {
  return (
    <Toolbar
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      variant="dense"
    >
      {title && (
        <Typography align="center" variant="h6">
          {title}
        </Typography>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    </Toolbar>
  );
};

export default MyToolbar;
