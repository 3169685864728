import React from "react";
import { useParams } from "react-router-dom";
import { useGetTopics } from "hooks/api";
import Topics from "./Topics";
import { Page } from "advancement-solutions-components/dist/components";
import GroupBanner from "./GroupBanner";
import Loader from "components/Loader";
import { PageContainer } from "advancement-solutions-components/dist/components";
import SkeletonList from "components/SkeletonList";
import Crumbs from "./Crumbs";
import MyToolbar from "components/MyToolbar";
import MyToolbarCenter from "components/MyToolbarCenter";

const TopicsPage = ({ match, user }) => {
  let { group } = useParams();
  const [topics, isLoading, error] = useGetTopics(group);
  return (
    <Page user={user}>
      <Crumbs />
      <MyToolbar>
        <MyToolbarCenter>
          <GroupBanner group={group} />
        </MyToolbarCenter>
      </MyToolbar>
      <PageContainer>
        <Loader
          datas={[topics]}
          data={topics}
          isLoadings={[isLoading]}
          LoadingComponent={SkeletonList}
          errors={[error]}
        >
          {(data) => <Topics group={group} variant="bib" topics={data} />}
        </Loader>
      </PageContainer>
    </Page>
  );
};

export default TopicsPage;
