import React, { Fragment } from "react";
import { useGetGroup } from "hooks/api";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";

const groupLookups = {
  pmk: "PMK-EE",
  "profile-sheet": "Profile Sheet",
  bib: "BIB",
  credential: "Credentials",
  warfare: "Warfare",
  qualification: "CLEP®",
};

const GroupBanner = ({ group }) => {
  const [g] = useGetGroup(group);
  return (
    <Box
      sx={{
        margin: 1,
      }}
    >
      {g ? (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {group === "bib" ? (
              <Fragment>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  BIB:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  {g.examType}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  {g.rate}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  {g.payGrade}
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  {groupLookups[group]}:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  {g.rate}
                </Typography>
              </Fragment>
            )}
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">
              <Skeleton width={150} />
            </Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default GroupBanner;
