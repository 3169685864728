import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { useGetTopic, useGetChapter } from "hooks/api";
import { Breadcrumbs } from "advancement-solutions-components/dist/components";
import Skeleton from "@mui/material/Skeleton";
import { Breadcrumb } from "advancement-solutions-components/dist/components";

const ChapterBreadCrumbs = ({ group, topicId, chapterId }) => {
  const [topic, topicIsLoading] = useGetTopic(group, topicId);
  const [chapter, chapterIsLoading] = useGetChapter(group, topicId, chapterId);

  return topicIsLoading || chapterIsLoading ? (
    <Skeleton height={24} width={350} />
  ) : (
    <Breadcrumbs aria-label="Breadcrumb" maxItems={5}>
      <Breadcrumb label="Home" to={`/`} icon={<HomeIcon fontSize="small" />} />
      <Breadcrumb label="Topics" to={`/${group}/topics`} />
      {topic && (
        <Breadcrumb label={topic.title} to={`/${group}/topics/${topicId}`} />
      )}
      {chapter && <Breadcrumb disabled label={chapter.title} />}
    </Breadcrumbs>
  );
};

export default ChapterBreadCrumbs;
