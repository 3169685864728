import React, { useContext, createContext, useState } from "react";
const appContext = createContext();

const ProvideContext = ({ children }) => {
  const ctx = useProvideContext();
  return <appContext.Provider value={ctx}>{children}</appContext.Provider>;
};

const useProvideContext = () => {
  const [bestSurvivalScore, setBestSurvivalScore] = useState();
  const [showCelebrate, setShowCelebrate] = useState(false);

  return {
    bestSurvivalScore,
    setBestSurvivalScore,
    showCelebrate,
    setShowCelebrate,
  };
};

export const useAppContext = () => {
  return useContext(appContext);
};

export default ProvideContext;
