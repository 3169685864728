import React from "react";
import Skeleton from "@mui/material/Skeleton";

const SkeletonCard = () => {
  return (
    <Skeleton
      sx={{
        maxWidth: 700,
        margin: 1,
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "flex-start",
      }}
      variant="rectangular"
      width={200}
      height={75}
    />
  );
};

export default SkeletonCard;
