import React from "react";
import Rating from "@mui/material/Rating";

const MyRating = ({ rating, onChange }) => {
  return (
    <div>
      {rating ? (
        <Rating
          name="simple-controlled"
          value={rating.rating || null}
          size="small"
          onChange={onChange}
        />
      ) : (
        <Rating
          name="simple-controlled"
          value={null}
          size="small"
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default MyRating;
