import React from "react";
import List from "@mui/material/List";
import Chapter from "./Chapter";
import ListSubheader from "components/ListSubheader";

const Chapters = ({ locked, group, chapters }) => {
  return (
    <List subheader={<ListSubheader text="Chapters" />}>
      {chapters.map((chapter) => (
        <Chapter
          key={chapter.id}
          locked={locked}
          group={group}
          chapter={chapter}
        />
      ))}
    </List>
  );
};

export default Chapters;
