import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs } from "advancement-solutions-components/dist/components";
import Skeleton from "@mui/material/Skeleton";
import { Breadcrumb } from "advancement-solutions-components/dist/components";

const NoTopicBreadCrumbs = ({ group }) => (
  <Breadcrumbs aria-label="Breadcrumb" maxItems={5}>
    <Breadcrumb label="Home" to={`/`} icon={<HomeIcon fontSize="small" />} />
    <Breadcrumb label="Topics" to={`/${group}/topics`} />
  </Breadcrumbs>
);

const TopicBreadCrumbs = ({ group, topic }) => (
  <Breadcrumbs aria-label="Breadcrumb" maxItems={5}>
    <Breadcrumb label="Home" to={`/`} icon={<HomeIcon fontSize="small" />} />
    <Breadcrumb label="Topics" to={`/${group}/topics`} />
    <Breadcrumb disabled label={topic.title} />
  </Breadcrumbs>
);

const Crumbs = ({ group, topic, isLoading }) => {
  return topic ? (
    <TopicBreadCrumbs group={group} topic={topic} />
  ) : isLoading ? (
    <Skeleton height={24} width={350} />
  ) : (
    <NoTopicBreadCrumbs group={group} />
  );
};

export default Crumbs;
