import React from "react";
import Bookmark from "@mui/icons-material/Bookmark";
import FormatPaint from "@mui/icons-material/FormatPaint";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DeckProgress from "components/DeckProgress";
import { useGetChapterDeckTotals } from "hooks/api";
import { Link } from "react-router-dom";

const ChapterIcon = () => (
  <Bookmark
    sx={{
      ml: 7,
      mr: 1,
    }}
  />
);

const UnderConstructionIcon = () => (
  <FormatPaint
    sx={{
      ml: 7,
      mr: 1,
      color: "warning.main",
    }}
  />
);

const ChapterListItem = ({ construction, locked, ...rest }) => (
  <ListItem
    sx={
      construction
        ? {
            borderLeftStyle: "solid",
            borderLeftWidth: 1,
            borderLeftColor: "warning.main",
          }
        : {}
    }
    button
    disabled={locked}
    component={Link}
    {...rest}
  />
);

const ChapterListIcon = ({ construction }) => (
  <ListItemIcon>
    {construction ? <UnderConstructionIcon /> : <ChapterIcon />}
  </ListItemIcon>
);

const Chapter = ({ locked, group, chapter }) => {
  const isConstruction = chapter.handling === "under-construction";
  const chapterUrl = `/${group}/topics/${chapter.topic}/chapters/${chapter.id}`;
  const [chapterDeckTotals] = useGetChapterDeckTotals(
    chapter.topic,
    chapter.id
  );
  return (
    <React.Fragment key={chapter.id}>
      <ChapterListItem to={chapterUrl} locked={locked}>
        <ChapterListIcon construction={isConstruction} />
        <ListItemText primary={chapter.title} />
      </ChapterListItem>
      <DeckProgress totals={chapterDeckTotals} />
      <Divider />
    </React.Fragment>
  );
};

export default Chapter;
