import React from "react";
import { useParams } from "react-router-dom";
import { Page } from "advancement-solutions-components/dist/components";
import Crumbs from "./Crumbs";
import Modes from "components/Modes";
import {
  useGetTopic,
  useGetChapter,
  useChapterReset,
  useGetQuestionsHead,
} from "hooks/api";
import Loader from "components/Loader";
import SkeletonList from "components/SkeletonList";
import ChapterRating from "components/ChapterRating";
import { PageContainer } from "advancement-solutions-components/dist/components";
import MyToolbar from "components/MyToolbar";
import MyToolbarLeft from "components/MyToolbarLeft";
import MyToolbarRight from "components/MyToolbarRight";
import { IconButton, Tooltip } from "@mui/material";
import ResetIcon from "@mui/icons-material/Restore";
import NoStudyModesWarning from "./NoStudyModesWarning";
import { modes } from "../../utils";
import { useGetChapterDeckTotals } from "hooks/api";
import DeckProgress from "components/DeckProgress";

const getActiveModes = (user, topic) =>
  modes.filter((x) => x.isEnabled(user, topic));

const Component = ({ user }) => {
  const { group, topicId, chapterId } = useParams();
  const [topicChapterTotals] = useGetChapterDeckTotals(topicId, chapterId);
  const [topic, isLoadingTopic, topicError] = useGetTopic(group, topicId);
  const [chapter, isLoadingChapter, chapterError] = useGetChapter(
    group,
    topicId,
    chapterId
  );
  const [headers] = useGetQuestionsHead(topicId, chapterId);
  const [reset] = useChapterReset(group, topicId);

  const rowCount = headers["x-record-count"];

  return (
    <Page user={user}>
      <Crumbs group={group} topicId={topicId} chapterId={chapterId} />
      {chapter && (
        <MyToolbar title={chapter.title}>
          <MyToolbarLeft>
            <Tooltip title="Reset">
              <IconButton
                color="primary"
                onClick={() => reset(topicId, chapterId)}
                size="large"
              >
                <ResetIcon />
              </IconButton>
            </Tooltip>
          </MyToolbarLeft>
          <MyToolbarRight>
            <ChapterRating topicId={topicId} chapterId={chapterId} />
          </MyToolbarRight>
        </MyToolbar>
      )}
      <PageContainer>
        {rowCount === 0 ? (
          <NoStudyModesWarning />
        ) : (
          <Loader
            datas={[topic, chapter, user]}
            data={chapter}
            isLoadings={[isLoadingTopic, isLoadingChapter]}
            LoadingComponent={SkeletonList}
            EmptyComponents={[NoStudyModesWarning]}
            errors={[topicError, chapterError]}
          >
            {() => (
              <>
                <DeckProgress totals={topicChapterTotals} />
                <Modes
                  recordCount={rowCount}
                  rootUrl={`/${group}/topics/${topicId}/chapters/${chapterId}`}
                  modes={getActiveModes(user, topic)}
                />
              </>
            )}
          </Loader>
        )}
      </PageContainer>
    </Page>
  );
};

export default Component;
