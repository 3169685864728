import FlashcardIcon from "@mui/icons-material/LibraryBooks";
import StudyGuideIcon from "@mui/icons-material/ChromeReaderMode";
import MultipleChoiceIcon from "@mui/icons-material/List";
import SurvivalIcon from "@mui/icons-material/FlashOn";
import AudioGuideIcon from "@mui/icons-material/Mic";
import TestIcon from "@mui/icons-material/Assessment";
import GameIcon from "@mui/icons-material/Gamepad";
import { LevelOne } from "advancement-solutions-components/dist/icons";
import { LevelTwo } from "advancement-solutions-components/dist/icons";
import { LevelThree } from "advancement-solutions-components/dist/icons";
import { LevelFour } from "advancement-solutions-components/dist/icons";
import { LevelFive } from "advancement-solutions-components/dist/icons";
import { LevelSix } from "advancement-solutions-components/dist/icons";
import { LevelSeven } from "advancement-solutions-components/dist/icons";
import { LevelEight } from "advancement-solutions-components/dist/icons";
import { LevelNine } from "advancement-solutions-components/dist/icons";
import { Advice } from "advancement-solutions-components/dist/icons";

export * from "./handlingConditions";

export const makeTest = (items = [], maxTime, maxQuestions) => {
  const questions = shuffle(items).slice(0, maxQuestions);
  const testQuestions = questions.reduce((acc, question) => {
    const answers = shuffle([
      {
        id: 1,
        title: question.distractor1,
        correct: false,
        selected: false,
        sort: 0,
      },
      {
        id: 2,
        title: question.distractor2,
        correct: false,
        selected: false,
        sort: 0,
      },
      {
        id: 3,
        title: question.distractor3,
        correct: false,
        selected: false,
        sort: 0,
      },
      {
        id: 0,
        title: question.answer,
        correct: true,
        selected: false,
        sort: 0,
      },
    ]);

    const testQuestion = {
      ...question,
      answered: false,
      answers: answers.map((x, i) => ({ ...x, sort: i })),
    };

    return {
      ...acc,
      [question.id]: testQuestion,
    };
  }, {});

  return {
    ids: questions.map((x) => x.id),
    questions: { ...testQuestions },
    graded: false,
    paused: false,
    status: "NEW",
    timeRemaining: maxTime,
  };
};

export const gradeExam = (test) => {
  if (!test) {
    return null;
  }
  const results = Object.values(test.questions).map((q) =>
    q.answers.some((a) => a.selected && a.correct)
  );
  const result = results.reduce(
    (acc, curr) => {
      return curr
        ? {
            correct: acc.correct + 1,
            incorrect: acc.incorrect,
          }
        : {
            correct: acc.correct,
            incorrect: acc.incorrect + 1,
          };
    },
    { correct: 0, incorrect: 0 }
  );
  return { ...result, score: (result.correct / results.length) * 100 };
};

export const wrapError = (err) => {
  return (err.response && err.response.data && err.response.data.error) || err;
};

export const prettyPrintToken = (token) =>
  token && token.slice(1, 5) + "..." + token.slice(-5);

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const shuffle = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const modes = [
  {
    id: "flashcards",
    title: "Flashcards",
    icon: FlashcardIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.quizModeEnabled && modeStatuses.quizModeEnabled,
    minRecords: 1,
  },
  {
    id: "study-guide",
    title: "Study Guide",
    icon: StudyGuideIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.studyGuideModeEnabled && modeStatuses.studyGuideModeEnabled,
    minRecords: 1,
  },
  {
    id: "multiple-choice",
    title: "Multiple Choice",
    icon: MultipleChoiceIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.quizModeEnabled && modeStatuses.quizModeEnabled,
    minRecords: 1,
  },
  {
    id: "audio-guide",
    title: "Audio Guide",
    icon: AudioGuideIcon,
    isFocusEnabled: false,
    isEnabled: (user, modeStatuses) =>
      user.audioGuideModeEnabled && modeStatuses.audioGuideModeEnabled,
    minRecords: 0,
  },
  {
    id: "game",
    title: "Game",
    icon: GameIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.gameModeEnabled && modeStatuses.gameModeEnabled,
    minRecords: 12,
  },
  {
    id: "survival",
    title: "Survival",
    icon: SurvivalIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.survivalModeEnabled && modeStatuses.survivalModeEnabled,
    minRecords: 10,
  },
  {
    id: "test",
    title: "Test",
    icon: TestIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) =>
      user.testingModeEnabled && modeStatuses.testingModeEnabled,
    minRecords: parseInt(process.env.REACT_APP_MAX_TEST_QUESTIONS, 10),
  },
  {
    id: "browse",
    title: "Browse",
    icon: StudyGuideIcon,
    isFocusEnabled: true,
    isEnabled: (user, modeStatuses) => user.isTester,
    minRecords: 0,
  },
];

export const getAdviceIcon = () => {
  const brand = process.env.REACT_APP_BRAND;
  return brand === "bmr" ? LevelSeven : Advice;
};

export const getLevelIcon = (level) => {
  switch (level) {
    case 1:
      return LevelOne;
    case 2:
      return LevelTwo;
    case 3:
      return LevelThree;
    case 4:
      return LevelFour;
    case 5:
      return LevelFive;
    case 6:
      return LevelSix;
    case 7:
      return LevelSeven;
    case 8:
      return LevelEight;
    case 9:
      return LevelNine;
    default:
      return LevelOne;
  }
};

export const isSurvivalScoreBest = (current, best) => {
  if (!best && current.correct > 0) {
    return true;
  } else if (best && current.correct < best.correct) {
    return false;
  } else if (best && current.correct > best.correct) {
    return true;
  } else if (
    best &&
    current.correct === best.correct &&
    current.time.duration < best.time.duration
  ) {
    return true;
  } else {
    return false;
  }
};

export const totalsToStats = (totals) => {
  const convertedObject = {
    easy: 0,
    normal: 0,
    hard: 0,
    new: 0,
  };

  if (!totals) {
    return convertedObject;
  }

  totals.forEach((total) => {
    const deck = total.deck !== null ? total.deck : -1;
    const count = parseInt(total.count);

    switch (deck) {
      case 0:
        convertedObject["easy"] = count;
        break;
      case 1:
        convertedObject["normal"] = count;
        break;
      case 2:
        convertedObject["hard"] = count;
        break;
      default:
        convertedObject["new"] = count;
        break;
    }
  });

  return convertedObject;
};
