import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { handlingConditions } from "../../../../utils";
import Chapters from "./Chapters";
import { useBranding } from "providers/ProvideBranding";
import { useGetTopicDeckTotals } from "hooks/api";
import DeckProgress from "components/DeckProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";

const TopicIcon = ({ handling, ...rest }) => {
  const condition = handlingConditions[handling];
  return <condition.icon {...rest} />;
};

const getStyles = (handling) => {
  const condition = handlingConditions[handling];
  return condition.style;
};

const Topic = ({ group, topic }) => {
  const [topicDeckTotals] = useGetTopicDeckTotals(topic.id);
  const { rawString } = useBranding();
  const styles = getStyles(topic.handling);
  const url = handlingConditions[topic.handling].link(group, topic);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const supportEmail = rawString("app.support.email");
  const handlingDescription =
    topic.handling &&
    handlingConditions[topic.handling].description({ supportEmail });
  const [showChapters, setShowChapters] = useState(false);

  const toggleChapters = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setShowChapters(!showChapters);
  };

  return (
    <React.Fragment key={topic.id}>
      <ListItemButton button component={Link} to={url} sx={styles.border}>
        <ListItemIcon>
          <TopicIcon handling={topic.handling} sx={styles.icon} />
        </ListItemIcon>
        <ListItemText primary={topic.title} secondary={handlingDescription} />
        <IconButton onClick={toggleChapters}>
          {showChapters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </ListItemButton>
      <DeckProgress totals={topicDeckTotals} />
      <Divider />
      {!isMobile &&
        Boolean(topic.handling === "unrestricted") &&
        showChapters && <Chapters group={group} topic={topic} />}
    </React.Fragment>
  );
};

export default Topic;
