import { useState, useEffect } from "react";
import { wrapError } from "../../utils";

const useGet = (api, path, defaultValue) => {
  const [headers, setHeaders] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    api
      .head(path)
      .then((res) => {
        setHeaders(res.headers);
      })
      .catch((err) => {
        setError(wrapError(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, path]);

  return [headers, isLoading, error];
};

export default useGet;
