import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useGetGroup, useGetUserStats } from "hooks/api";
import MoreIcon from "@mui/icons-material/Forward";
import { IconButton } from "@mui/material";
import SmallNavigationCard from "./SmallNavigationCard";
import StatCard from "./StatCard";
import SkeletonCard from "./SkeletonCard";
import SkeletonStatCard from "./SkeletonStatCard";
import * as routes from "routes";
import MyToolbar from "components/MyToolbar";
import MyToolbarCenter from "components/MyToolbarCenter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ColumnContainer } from "advancement-solutions-components/dist/components";
import { Box } from "@mui/system";

const SmallStatCards = ({ children }) => (
  <Box
    sx={{
      maxWidth: 700,
      margin: 1,
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
    }}
  >
    {children}
  </Box>
);

const SmallCards = ({ isMobile, children }) => (
  <Box
    sx={{
      maxWidth: 700,
      margin: 1,
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: isMobile ? "center" : "center",
    }}
  >
    {children}
  </Box>
);

const NavigationBmr = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [bib] = useGetGroup("bib");
  const [credentials] = useGetGroup("credential");
  const [warfare] = useGetGroup("warfare");
  const [qualification] = useGetGroup("qualification");
  const [profileSheet] = useGetGroup("profile-sheet");
  const [stats] = useGetUserStats();

  return (
    <ColumnContainer>
      <div>
        <MyToolbar>
          <MyToolbarCenter center title="Advancement Exam" />
        </MyToolbar>
        <SmallCards isMobile={isMobile}>
          {!profileSheet ? (
            <SkeletonCard isMobile={isMobile} />
          ) : (
            profileSheet.enabled && (
              <SmallNavigationCard
                title="Profile Sheet"
                description="Study questions organized by your Profile Sheet. We recommend this study method."
                url="/profile-sheet/topics"
              />
            )
          )}
          {!bib ? (
            <SkeletonCard />
          ) : (
            bib.enabled && (
              <SmallNavigationCard
                title="BIB"
                description="Study questions organized by your Bibliography."
                url="/bib/topics"
              />
            )
          )}
          <SmallNavigationCard
            title="Mock Exam"
            description="A timed exam designed to simulate a real advancement exam."
            url={routes.MOCK_EXAM}
          />
        </SmallCards>
        {((credentials && credentials.enabled) ||
          (warfare && warfare.enabled) ||
          (qualification && qualification.enabled)) && (
          <MyToolbar>
            <MyToolbarCenter center title="Career Advancement" />
          </MyToolbar>
        )}
        <SmallCards isMobile={isMobile}>
          {!credentials ? (
            <SkeletonCard />
          ) : (
            credentials.enabled && (
              <SmallNavigationCard
                title="Credentials"
                description="Study questions organized by your Credentials."
                url="/credential/topics"
              />
            )
          )}
          {!warfare ? (
            <SkeletonCard />
          ) : (
            warfare.enabled && (
              <SmallNavigationCard
                title="Warfare"
                description="Study questions organized by your Warfare Area."
                url="/warfare/topics"
              />
            )
          )}
          {!qualification ? (
            <SkeletonCard />
          ) : (
            qualification.enabled && (
              <SmallNavigationCard
                title="CLEP®"
                description="Study questions organized by your CLEP® requirements."
                url="/qualification/topics"
              />
            )
          )}
        </SmallCards>
        <MyToolbar>
          <MyToolbarCenter center title="Retention Enhancement" />
        </MyToolbar>
        <SmallCards isMobile={isMobile}>
          <SmallNavigationCard
            title="Difficult Questions"
            description="Study questions that you've previously marked as hard."
            url="/questions/difficult"
          />
          <SmallNavigationCard
            title="Fading&nbsp; Questions"
            description="Study questions that you haven't see in a while."
            url="/questions/fading"
          />
        </SmallCards>
        <MyToolbar>
          <MyToolbarCenter center title="Progress" />
        </MyToolbar>
        <SmallCards>
          <SmallNavigationCard
            title="Achievements"
            description="Achievements that you've accomplished."
            url="/profile/progress?tab=achievements"
          />
          <SmallNavigationCard
            title="Challenges"
            description="Challenges that you've completed."
            url="/profile/progress?tab=challenges"
          />
          <SmallNavigationCard
            title="Stats"
            description="Your in app stats."
            url="/profile/progress?tab=stats"
          />
        </SmallCards>

        <MyToolbar>
          <MyToolbarCenter center title="Study Decks" />
        </MyToolbar>

        {!stats ? (
          <SmallStatCards>
            <SkeletonStatCard />
            <SkeletonStatCard />
            <SkeletonStatCard />
          </SmallStatCards>
        ) : (
          <SmallStatCards>
            <StatCard
              title="Easy"
              value={stats.user.easy}
              variant="easy"
              url="/questions/easy"
            />
            <StatCard
              title="Normal"
              value={stats.user.normal}
              variant="normal"
              url="/questions/normal"
            />
            <StatCard
              title="Hard"
              value={stats.user.hard}
              variant="hard"
              url="/questions/hard"
            />
          </SmallStatCards>
        )}
      </div>
    </ColumnContainer>
  );
};

export default NavigationBmr;
