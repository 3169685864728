export const challengeIds = [
  "ca0005",
  "ca0010",
  "ca0025",
  "ca0050",
  "ca0100",
  "ca0150",
  "ca0200",
  "ca0250",
  "ca0300",
  "cm0005",
  "cm0010",
  "cm0025",
  "cm0050",
  "cm0100",
  "cm0150",
  "cm0200",
  "cm0250",
  "cm0300",
  "mu0001",
];
export const challenges = {
  // Answer
  ca0005: {
    title: "Answer Questions",
    description: "Answer 5 questions.",
    level: 1,
    order: 1,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 5,
  },
  ca0010: {
    title: "Answer Questions",
    description: "Answer 10 questions.",
    level: 2,
    order: 2,
    status: "hidden",
    current: 0,
    total: 10,
  },
  ca0025: {
    title: "Answer Questions",
    description: "Answer 25 questions.",
    level: 3,
    order: 3,
    status: "hidden",
    current: 0,
    total: 25,
  },
  ca0050: {
    title: "Answer Questions",
    description: "Answer 50 questions.",
    level: 4,
    order: 4,
    status: "hidden",
    current: 0,
    total: 50,
  },
  ca0100: {
    title: "Answer Questions",
    description: "Answer 100 questions.",
    level: 5,
    order: 5,
    status: "hidden",
    current: 0,
    total: 100,
  },
  ca0150: {
    title: "Answer Questions",
    description: "Answer 150 questions.",
    level: 6,
    order: 6,
    status: "hidden",
    current: 0,
    total: 150,
  },
  ca0200: {
    title: "Answer Questions",
    description: "Answer 200 questions.",
    level: 7,
    order: 7,
    status: "hidden",
    current: 0,
    total: 200,
  },
  ca0250: {
    title: "Answer Questions",
    description: "Answer 250 questions.",
    level: 8,
    order: 8,
    status: "hidden",
    current: 0,
    total: 250,
  },
  ca0300: {
    title: "Answer Questions",
    description: "Answer 300 questions.",
    level: 9,
    order: 9,
    status: "hidden",
    current: 0,
    total: 300,
  },
  // Move
  cm0005: {
    title: "Move Questions",
    description: "Move 5 questions into a deck.",
    level: 1,
    order: 10,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 5,
  },
  cm0010: {
    title: "Move Questions",
    description: "Move 10 questions into a deck.",
    level: 2,
    order: 11,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 10,
  },
  cm0025: {
    title: "Move Questions",
    description: "Move 25 questions into a deck.",
    level: 3,
    order: 12,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 25,
  },
  cm0050: {
    title: "Move Questions",
    description: "Move 50 questions into a deck.",
    level: 4,
    order: 13,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 50,
  },
  cm0100: {
    title: "Move Questions",
    description: "Move 100 questions into a deck.",
    level: 5,
    order: 14,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 100,
  },
  cm0150: {
    title: "Move Questions",
    description: "Move 150 questions into a deck.",
    level: 6,
    order: 15,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 150,
  },
  cm0200: {
    title: "Move Questions",
    description: "Move 200 questions into a deck.",
    level: 7,
    order: 16,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 200,
  },
  cm0250: {
    title: "Move Questions",
    description: "Move 250 questions into a deck.",
    level: 8,
    order: 17,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 250,
  },
  cm0300: {
    title: "Move Questions",
    description: "Move 300 questions into a deck.",
    level: 9,
    order: 18,
    status: "hidden",
    unlockedAt: null,
    current: 0,
    total: 300,
  },
  // Use Modes
  mu0001: {
    title: "Use Every Study Mode",
    description: "Study using all 8 study modes.",
    level: null,
    order: 19,
    status: "revealed",
    unlockedAt: null,
    list: [
      { name: "Flashcards", value: false },
      { name: "StudyGuide", value: false },
      { name: "AudioGuide", value: false },
      { name: "MultipleChoice", value: false },
      { name: "Game", value: false },
      { name: "Survival", value: false },
      { name: "Test", value: false },
      { name: "MockExam", value: false },
    ],
  },
};
