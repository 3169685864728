import React from "react";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { ListItem, ListItemIcon } from "@mui/material";

const SkeletonList = ({ rows = 5 }) => {
  return (
    <div>
      <ListItem
        sx={{
          padding: 2,
        }}
      >
        <Skeleton variant="text" width={100} />
      </ListItem>
      {[...Array(rows).keys()].map((row) => {
        return (
          <div key={row}>
            <Divider />
            <ListItem
              sx={{
                padding: 2,
              }}
            >
              <ListItemIcon>
                <Skeleton variant="circular" width={24} height={24} />
              </ListItemIcon>
              <Skeleton variant="text" width="100%" />
            </ListItem>
          </div>
        );
      })}
    </div>
  );
};

export default SkeletonList;
