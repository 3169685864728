import React from "react";
import NoContentWarning from "./NoContentWarning";

const DefaultLoading = () => (
  <NoContentWarning warning="Please wait a moment while we're loading data." />
);
const DefaultEmpty = () => (
  <NoContentWarning warning="We couldn't find anything." />
);
const DefaultError = ({ error }) => (
  <NoContentWarning warning={error.message} />
);

const hasData = (data) => {
  if (Array.isArray(data)) {
    return Boolean(data) && data.length > 0;
  } else {
    return Boolean(data);
  }
};

const hasDatas = (data) => data.map((x) => hasData(x));

const anyEmpty = (data) => data.some((x) => x === false);

const anyErrors = (errors) => errors.some((x) => x);

const anyLoading = (loading) => {
  return loading.some((x) => x === true);
};

const Loader = ({
  data = null,
  datas = [],
  isLoadings = [],
  errors = [],
  LoadingComponent = DefaultLoading,
  EmptyComponents = [],
  ErrorComponents = [],
  children,
}) => {
  const problems = anyErrors(errors);
  const datums = hasDatas(datas);
  const ready = !anyLoading(isLoadings);
  const empty = anyEmpty(datums);

  const SelectEmptyComponent = ({ index }) => {
    const Component =
      EmptyComponents.length > 0 ? EmptyComponents[index] : DefaultEmpty;
    return <Component />;
  };

  const SelectErrorComponent = ({ index, errors }) => {
    const Component =
      ErrorComponents.length > 0 ? ErrorComponents[index] : DefaultError;
    const error = errors.find((x) => x);
    return <Component error={error} />;
  };

  const emptyIndex =
    empty &&
    datas.findIndex((x) => {
      if (Array.isArray(x)) {
        return x.length === 0;
      } else {
        return Boolean(x);
      }
    });

  const errorIndex = problems && errors.findIndex((x) => x);

  return problems ? (
    <SelectErrorComponent index={errorIndex} errors={errors} />
  ) : !ready ? (
    <LoadingComponent />
  ) : empty ? (
    <SelectEmptyComponent index={emptyIndex} />
  ) : (
    children(data)
  );
};

export default Loader;
