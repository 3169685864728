import { useProgressStorage } from "hooks/api";
import React, { useContext, createContext, useReducer, useEffect } from "react";
import { reducer } from "./reducer";
import { initialState } from "./initialState";
import { gatherStats } from "./stats";
import { useAnnouncement } from "providers/ProvideAnnouncements";

export const sources = {
  Flashcards: "Flashcards",
  MultipleChoice: "MultipleChoice",
  Game: "Game",
  Survival: "Survival",
  Test: "Test",
  MockExam: "MockExam",
  AudioGuide: "AudioGuide",
  StudyGuide: "StudyGuide",
};

export const interactions = {
  Answer: "Answer",
  Move: "Move",
  FinishContest: "FinishContest",
  ViewPage: "ViewPage",
};

export const results = {
  Right: "Right",
  Wrong: "Wrong",
  Easy: "Easy",
  Normal: "Normal",
  Hard: "Hard",
  Win: "Win",
  Lose: "Lose",
};

export const actions = {
  interaction: "interaction",
  load: "load",
  success: "success",
  failure: "failuer",
};

const achievementContext = createContext();

const ProvideAchievements = ({ children }) => {
  const { send } = useAnnouncement();
  const [get, save, isLoading, error] = useProgressStorage();
  const [current, dispatch] = useReducer(reducer(send), initialState);
  const ctx = useProvideAchievements(current, dispatch, get);

  useEffect(() => {
    dispatch({
      type: actions.load,
      payload: null,
    });

    const getProgress = async () => {
      try {
        const { progress } = await get();
        dispatch({
          type: actions.success,
          payload: progress || initialState,
        });
      } catch {
        dispatch({
          type: actions.failure,
          payload: null,
        });
      }
    };
    getProgress();
  }, []);

  useEffect(() => {
    current.global.touched && save(current);
  }, [current]);

  return (
    <achievementContext.Provider value={ctx}>
      {children}
    </achievementContext.Provider>
  );
};

const useProvideAchievements = (progress, dispatch, get) => {
  const interactWithQuestion = (interaction, questionId, result, source) => {
    dispatch({
      type: actions.interaction,
      payload: {
        interaction,
        questionId,
        result,
        source,
      },
    });
  };

  const interact = (interaction, source) => {
    dispatch({
      type: actions.interaction,
      payload: {
        interaction,
        questionId: null,
        result: null,
        source,
      },
    });
  };

  const interactWithScore = (interaction, score, result, source) => {
    dispatch({
      type: actions.interaction,
      payload: {
        interaction,
        score,
        result,
        source,
      },
    });
  };

  const touch = () => {
    dispatch({
      type: actions.touch,
      payload: null,
    });
  };

  const load = () => {
    dispatch({
      type: actions.load,
      payload: null,
    });

    const getProgress = async () => {
      try {
        const { progress } = await get();
        dispatch({
          type: actions.success,
          payload: progress || initialState,
        });
      } catch {
        dispatch({
          type: actions.failure,
          payload: null,
        });
      }
    };
    getProgress();
  };

  return {
    load,
    interactWithQuestion,
    interactWithScore,
    interact,
    touch,
    achievements: progress.achievements,
    challenges: progress.challenges,
    stats: gatherStats(progress),
    progress,
  };
};

export const useAchievementContext = () => {
  return useContext(achievementContext);
};

export default ProvideAchievements;
