import { achievements, achievementIds } from "./achievements";
import { challenges, challengeIds } from "./challenges";

export const initialState = {
  version: 1,
  global: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
  },
  Flashcards: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
  },
  MultipleChoice: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
  },
  StudyGuide: { touched: null },
  AudioGuide: { touched: null },
  Game: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
    Win: 0,
    Lose: 0,
    WinStreak: 0,
    LoseStreak: 0,
    lastScore: null,
    bestScore: null,
  },
  Survival: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
    Win: 0,
    Lose: 0,
    WinStreak: 0,
    LoseStreak: 0,
    lastScore: null,
    bestScore: null,
  },
  Test: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
    Win: 0,
    Lose: 0,
    WinStreak: 0,
    LoseStreak: 0,
    lastScore: null,
    bestScore: null,
  },
  MockExam: {
    touched: null,
    midnight: null,
    dayStreak: 0,
    moved: 0,
    answered: 0,
    Easy: 0,
    Normal: 0,
    Hard: 0,
    EasyStreak: 0,
    NormalStreak: 0,
    HardStreak: 0,
    Right: 0,
    Wrong: 0,
    RightStreak: 0,
    WrongStreak: 0,
    Win: 0,
    Lose: 0,
    WinStreak: 0,
    LoseStreak: 0,
    lastScore: null,
    bestScore: null,
  },
  achievementIds,
  achievements,
  challengeIds,
  challenges,
  loading: false,
  error: null,
};
