import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Button as MuiButton } from "@mui/material";
import { Select as MuiSelect } from "@mui/material";
import { TextField as MuiTextField } from "@mui/material";
import { InputLabel as MuiInputLabel } from "@mui/material";
import { FormControl as MuiFormControl } from "@mui/material";

export const Form = ({ children, ...rest }) => (
  <form {...rest} style={{ maxWidth: 500, width: "100%", marginTop: 8 }}>
    {children}
  </form>
);

export const Button = ({ sx, children, fullWidth, ...rest }) => (
  <MuiButton
    fullWidth={fullWidth}
    {...rest}
    sx={{
      ...(fullWidth ? { mt: 1 } : { m: 2 }),
      ...sx,
    }}
  >
    {children}
  </MuiButton>
);

export const Link = ({ children, ...rest }) => (
  <RouterLink
    {...rest}
    style={{
      textDecoration: "none",
      m: 1,
      p: 1,
      minWidth: "150px",
    }}
  >
    <Typography
      color="primary"
      variant="subtitle2"
      align="center"
      sx={{
        m: 0.5,
        p: 0.5,
      }}
    >
      {children}
    </Typography>
  </RouterLink>
);

export const Select = ({ children, ...rest }) => (
  <MuiSelect {...rest}>{children}</MuiSelect>
);

export const TextField = ({ children, ...rest }) => (
  <MuiTextField
    InputLabelProps={{ style: { padding: 2 } }}
    {...rest}
    sx={{
      p: 0,
      mt: 1,
    }}
  >
    {children}
  </MuiTextField>
);

export const InputLabel = ({ children, ...rest }) => (
  <MuiInputLabel
    {...rest}
    sx={{
      padding: 0.25,
    }}
  >
    {children}
  </MuiInputLabel>
);

export const FormControl = ({ children, ...rest }) => (
  <MuiFormControl {...rest} sx={{ mt: 1 }}>
    {children}
  </MuiFormControl>
);
