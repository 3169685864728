import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Brand } from "advancement-solutions-components/dist/icons";
import { useAuth } from "providers/ProvideAuth";
import {
  HOME,
  USER_DETAILS,
  SUBSCRIPTION,
  CHANGE_PASSWORD,
  PROGRESS,
} from "routes";
import { BrandedTypography } from "providers/ProvideBranding";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const MenuAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const shortVersion = (version) => {
    if (version.includes("-")) {
      const parts = version.split("-");
      return parts[0];
    } else {
      return version;
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            component={Link}
            to={HOME}
            edge="start"
            sx={{
              mr: 2,
            }}
            color="inherit"
            aria-label="menu"
            size="large"
          >
            <Brand brand={process.env.REACT_APP_BRAND} />
          </IconButton>
          {matches ? (
            <Typography variant="caption">
              {process.env.REACT_APP_VERSION}
            </Typography>
          ) : (
            <Typography variant="caption">
              {shortVersion(process.env.REACT_APP_VERSION)}
            </Typography>
          )}
          <BrandedTypography
            sx={{
              flexGrow: 1,
            }}
            align="center"
            id="app.title"
            variant="h6"
          />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            size="large"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to={USER_DETAILS} onClick={handleClose}>
              Profile
            </MenuItem>
            <MenuItem component={Link} to={PROGRESS} onClick={handleClose}>
              Progress
            </MenuItem>
            <MenuItem component={Link} to={SUBSCRIPTION} onClick={handleClose}>
              Subscription
            </MenuItem>
            <MenuItem
              component={Link}
              to={CHANGE_PASSWORD}
              onClick={handleClose}
            >
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>Sign out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MenuAppBar;
