import React, { Fragment } from "react";
import List from "@mui/material/List";
import Mode from "./Mode";
import SkeletonList from "../SkeletonList";
import ListSubheader from "../ListSubheader";

const Modes = ({ rootUrl, modes, recordCount }) => {
  return (
    <List subheader={<ListSubheader text="Choose a Study Mode" />}>
      {!modes ? (
        <SkeletonList />
      ) : (
        <Fragment key="modes">
          {modes.map((mode) => {
            return (
              recordCount &&
              recordCount >= mode.minRecords && (
                <Mode key={mode.id} rootUrl={rootUrl} mode={mode} />
              )
            );
          })}
        </Fragment>
      )}
    </List>
  );
};

export default Modes;
