import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";

const StatCardHeader = ({ sx, ...rest }) => (
  <CardHeader sx={{ p: 1, ...sx }} {...rest} />
);

const StatCard = ({ title, value, variant = "easy", url }) => {
  const variants = {
    easy: {
      backgroundColor: "success.main",
    },
    normal: {
      backgroundColor: "primary.main",
    },
    hard: {
      backgroundColor: "error.main",
    },
  };

  return (
    <Card
      sx={{
        margin: 1,
        width: 195,
      }}
    >
      <CardActionArea component={Link} to={url}>
        <StatCardHeader
          sx={variants[variant]}
          title={
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
              }}
              variant="subtitle1"
              align="center"
            >
              {title}
            </Typography>
          }
        />
        <CardContent>
          <Typography align="center" gutterBottom variant="h6" component="h2">
            {value}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StatCard;
