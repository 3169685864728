import { interactions, results, sources } from ".";
import { initialState } from "./initialState";
import { isSurvivalScoreBest } from "utils";
import {
  AchievementMessage,
  ChallengeMessage,
} from "providers/ProvideAnnouncements";

const isBestScore = (current, best, source) => {
  if (!best) {
    return current;
  }

  if (source === sources.Game) {
    return current.level > best.level ? current : best;
  } else if (source === sources.Survival) {
    return isSurvivalScoreBest(current, best) ? current : best;
  } else if (source === sources.Test) {
    return current.score > best.score ? current : best;
  } else if (source === sources.MockExam) {
    return current.score > best.score ? current : best;
  } else {
    return current;
  }
};

const processInteractionType = (state, action) => {
  if (action.payload.interaction === interactions.Move) {
    return {
      ...state,
      global: {
        ...state.global,
        moved: state[action.payload.source].moved + 1,
        [action.payload.result]: state.global[action.payload.result] + 1,
      },
      [action.payload.source]: {
        ...state[action.payload.source],
        moved: state[action.payload.source].moved + 1,
        [action.payload.result]:
          state[action.payload.source][action.payload.result] + 1,
      },
    };
  } else if (action.payload.interaction === interactions.Answer) {
    return {
      ...state,
      global: {
        ...state.global,
        answered: state.global.answered + 1,
        [action.payload.result]: state.global[action.payload.result] + 1,
      },
      [action.payload.source]: {
        ...state[action.payload.source],
        answered: state[action.payload.source].answered + 1,
        [action.payload.result]:
          state[action.payload.source][action.payload.result] + 1,
      },
    };
  } else if (action.payload.interaction === interactions.FinishContest) {
    return {
      ...state,
      [action.payload.source]: {
        ...state[action.payload.source],
        lastScore: action.payload.score,
        bestScore: isBestScore(
          action.payload.score,
          state[action.payload.source].bestScore,
          action.payload.source
        ),
        [action.payload.result]:
          state[action.payload.source][action.payload.result] + 1,
      },
    };
  } else {
    return state;
  }
};

const processWrongStreak = (state, action) => {
  return {
    ...state,
    global: {
      ...state.global,
      WrongStreak: state.global.WrongStreak + 1,
      RightStreak: 0,
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      WrongStreak: state[action.payload.source].WrongStreak + 1,
      RightStreak: 0,
    },
  };
};

const processRightStreak = (state, action) => {
  return {
    ...state,
    global: {
      ...state.global,
      RightStreak: state.global.RightStreak + 1,
      WrongStreak: 0,
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      RightStreak: state[action.payload.source].RightStreak + 1,
      WrongStreak: 0,
    },
  };
};

const processEasyStreak = (state, action) => {
  return {
    ...state,
    global: {
      ...state.global,
      EasyStreak: state.global.EasyStreak + 1,
      NormalStreak: 0,
      HardStreak: 0,
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      EasyStreak: state[action.payload.source].EasyStreak + 1,
      NormalStreak: 0,
      HardStreak: 0,
    },
  };
};

const processNormalStreak = (state, action) => {
  return {
    ...state,
    global: {
      ...state.global,
      EasyStreak: 0,
      NormalStreak: state.global.NormalStreak + 1,
      HardStreak: 0,
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      EasyStreak: 0,
      NormalStreak: state[action.payload.source].NormalStreak + 1,
      HardStreak: 0,
    },
  };
};

const processHardStreak = (state, action) => {
  return {
    ...state,
    global: {
      ...state.global,
      EasyStreak: 0,
      NormalStreak: 0,
      HardStreak: state.global.HardStreak + 1,
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      EasyStreak: 0,
      NormalStreak: 0,
      HardStreak: state[action.payload.source].HardStreak + 1,
    },
  };
};

const processWinStreak = (state, action) => {
  return {
    ...state,
    [action.payload.source]: {
      ...state[action.payload.source],
      WinStreak: state[action.payload.source].WinStreak + 1,
      LoseStreak: 0,
    },
  };
};

const processLoseStreak = (state, action) => {
  return {
    ...state,
    [action.payload.source]: {
      ...state[action.payload.source],
      LoseStreak: state[action.payload.source].LoseStreak + 1,
      WinStreak: 0,
    },
  };
};

const basicUnlock = (total, area, identifier, send) => (achievement, state) => {
  const unlocked = state[area][identifier] === total;
  const date = new Date();
  if (achievement.status !== "unlocked" && unlocked) {
    send(<AchievementMessage message={achievement.description} />);
  }
  return {
    ...achievement,
    status: unlocked ? "unlocked" : achievement.status,
    unlockedAt: unlocked ? date.toISOString() : achievement.unlockedAt,
  };
};

const isUnlocked = (action, interactionType, challenge) => {
  return (
    action.payload.interaction === interactionType &&
    challenge.current + 1 >= challenge.total
  );
};

const totalCounter = (interactionType, send) => (challenge, state, action) => {
  if (action.payload.interaction !== interactionType) {
    return {
      ...challenge,
    };
  }

  const date = new Date();
  const unlocked = isUnlocked(action, interactionType, challenge);
  if (challenge.status !== "unlocked" && unlocked) {
    send(<ChallengeMessage message={challenge.description} />);
  }

  return {
    ...challenge,
    current:
      action.payload.interaction === interactionType
        ? challenge.current < challenge.total
          ? challenge.current + 1
          : challenge.current
        : challenge.current,
    status: unlocked
      ? "unlocked"
      : challenge.current > 0
      ? "revealed"
      : challenge.status,
    unlockedAt:
      challenge.unlockedAt === null && unlocked
        ? date.toISOString()
        : challenge.unlockedAt,
  };
};

const listProgressCheck = (send) => (challenge, state, action) => {
  const date = new Date();
  const newValues = challenge.list
    .filter((x) => x.value === false)
    .map((x) => ({ name: x.name, value: !!state[x.name].touched }));
  const oldValues = challenge.list.filter((x) => x.value === true);
  const list = [...newValues, ...oldValues];
  const unlocked = list.every((x) => x.value);

  if (challenge.status !== "unlocked" && unlocked) {
    send(<ChallengeMessage message={challenge.description} />);
  }

  return {
    ...challenge,
    list,
    status: unlocked ? "unlocked" : challenge.status,
    unlockedAt:
      challenge.unlockedAt === null && unlocked
        ? date.toISOString()
        : challenge.unlockedAt,
  };
};

const progressFunctions = (interactions, send) => {
  return {
    // counting questions answered
    ca0005: totalCounter(interactions.Answer, send),
    ca0010: totalCounter(interactions.Answer, send),
    ca0025: totalCounter(interactions.Answer, send),
    ca0050: totalCounter(interactions.Answer, send),
    ca0100: totalCounter(interactions.Answer, send),
    ca0150: totalCounter(interactions.Answer, send),
    ca0200: totalCounter(interactions.Answer, send),
    ca0250: totalCounter(interactions.Answer, send),
    ca0300: totalCounter(interactions.Answer, send),
    // counting questions moved
    cm0005: totalCounter(interactions.Move, send),
    cm0010: totalCounter(interactions.Move, send),
    cm0025: totalCounter(interactions.Move, send),
    cm0050: totalCounter(interactions.Move, send),
    cm0100: totalCounter(interactions.Move, send),
    cm0150: totalCounter(interactions.Move, send),
    cm0200: totalCounter(interactions.Move, send),
    cm0250: totalCounter(interactions.Move, send),
    cm0300: totalCounter(interactions.Move, send),
    // counting modes used
    mu0001: listProgressCheck(send),
    // tracking right answer streak
    rs0005: basicUnlock(5, "global", "RightStreak", send),
    rs0010: basicUnlock(10, "global", "RightStreak", send),
    rs0025: basicUnlock(25, "global", "RightStreak", send),
    rs0050: basicUnlock(50, "global", "RightStreak", send),
    rs0100: basicUnlock(100, "global", "RightStreak", send),
    rs0150: basicUnlock(150, "global", "RightStreak", send),
    rs0200: basicUnlock(200, "global", "RightStreak", send),
    rs0250: basicUnlock(250, "global", "RightStreak", send),
    rs0300: basicUnlock(300, "global", "RightStreak", send),
    // tracking wrong answer streak
    ws0005: basicUnlock(5, "global", "WrongStreak", send),
    ws0010: basicUnlock(10, "global", "WrongStreak", send),
    ws0025: basicUnlock(25, "global", "WrongStreak", send),
    ws0050: basicUnlock(50, "global", "WrongStreak", send),
    ws0100: basicUnlock(100, "global", "WrongStreak", send),
    ws0150: basicUnlock(150, "global", "WrongStreak", send),
    ws0200: basicUnlock(200, "global", "WrongStreak", send),
    ws0250: basicUnlock(250, "global", "WrongStreak", send),
    ws0300: basicUnlock(300, "global", "WrongStreak", send),
    // tracking easy pile streak
    es0005: basicUnlock(5, "global", "EasyStreak", send),
    es0010: basicUnlock(10, "global", "EasyStreak", send),
    es0025: basicUnlock(25, "global", "EasyStreak", send),
    es0050: basicUnlock(50, "global", "EasyStreak", send),
    es0100: basicUnlock(100, "global", "EasyStreak", send),
    es0150: basicUnlock(150, "global", "EasyStreak", send),
    es0200: basicUnlock(200, "global", "EasyStreak", send),
    es0250: basicUnlock(250, "global", "EasyStreak", send),
    es0300: basicUnlock(300, "global", "EasyStreak", send),
    // tracking hard pile streak
    hs0005: basicUnlock(5, "global", "HardStreak", send),
    hs0010: basicUnlock(10, "global", "HardStreak", send),
    hs0025: basicUnlock(25, "global", "HardStreak", send),
    hs0050: basicUnlock(50, "global", "HardStreak", send),
    hs0100: basicUnlock(100, "global", "HardStreak", send),
    hs0150: basicUnlock(150, "global", "HardStreak", send),
    hs0200: basicUnlock(200, "global", "HardStreak", send),
    hs0250: basicUnlock(250, "global", "HardStreak", send),
    hs0300: basicUnlock(300, "global", "HardStreak", send),
    // tracking days studying streak
    ds0001: basicUnlock(1, "global", "dayStreak", send),
    ds0007: basicUnlock(7, "global", "dayStreak", send),
    ds0014: basicUnlock(14, "global", "dayStreak", send),
    ds0030: basicUnlock(30, "global", "dayStreak", send),
    ds0060: basicUnlock(60, "global", "dayStreak", send),
    ds0090: basicUnlock(90, "global", "dayStreak", send),
    ds0120: basicUnlock(120, "global", "dayStreak", send),
    ds0180: basicUnlock(180, "global", "dayStreak", send),
    ds0365: basicUnlock(365, "global", "dayStreak", send),
  };
};

const calculateStreak = (isoDate, count) => {
  if (isoDate === null) {
    return 1;
  }

  const now = new Date(new Date().setHours(0, 0, 0, 0));
  const then = new Date(isoDate);
  const diff = Math.floor((now - then) / (1000 * 60 * 60 * 24));

  if (diff > 1) {
    return 1;
  }

  if (diff === 1) {
    return count + 1;
  }

  if (diff === 0 && count === 0) {
    return 1;
  }

  return count;
};

const touch = (state, action) => {
  const rightNow = new Date();
  const rightNowDate = new Date(new Date().setHours(0, 0, 0, 0));

  return {
    ...state,
    global: {
      ...state.global,
      touched: rightNow.toISOString(),
      midnight: rightNowDate.toISOString(),
      dayStreak: calculateStreak(state.global.midnight, state.global.dayStreak),
    },
    [action.payload.source]: {
      ...state[action.payload.source],
      touched: rightNow.toISOString(),
      midnight: rightNowDate.toISOString(),
      dayStreak: calculateStreak(
        state[action.payload.source].midnight,
        state[action.payload.source].dayStreak
      ),
    },
  };
};

const processAchievements = (state, action, send) => {
  const fncs = progressFunctions(interactions, send);
  const newAchievements = state.achievementIds.reduce((acc, id) => {
    const achievement = state.achievements[id];
    const newAchievement = fncs[id](achievement, state, action);
    return {
      ...acc,
      [id]: newAchievement,
    };
  }, {});
  return {
    ...state,
    achievements: newAchievements,
  };
};

const processChallenges = (state, action, send) => {
  const fncs = progressFunctions(interactions, send);
  const newChallenges = state.challengeIds.reduce((acc, id) => {
    const challenge = state.challenges[id];
    const newChallenge = fncs[id](challenge, state, action);
    return {
      ...acc,
      [id]: newChallenge,
    };
  }, {});
  return {
    ...state,
    challenges: newChallenges,
  };
};

const processTotalsAndStreaks = (state, action) => {
  const newState = processInteractionType(state, action);

  if (action.payload.result === results.Right) {
    return processRightStreak(newState, action);
  } else if (action.payload.result === results.Wrong) {
    return processWrongStreak(newState, action);
  } else if (action.payload.result === results.Easy) {
    return processEasyStreak(newState, action);
  } else if (action.payload.result === results.Normal) {
    return processNormalStreak(newState, action);
  } else if (action.payload.result === results.Hard) {
    return processHardStreak(newState, action);
  } else if (action.payload.result === results.Win) {
    return processWinStreak(newState, action);
  } else if (action.payload.result === results.Lose) {
    return processLoseStreak(newState, action);
  } else {
    return newState;
  }
};

export const tryMerge = (userState) => {
  return initialState.version > userState.version
    ? {
        ...initialState,
        ...userState,
        achievementIds: initialState.achievementIds,
        achievements: {
          ...initialState.achievements,
          ...userState.achievements,
        },
        challengeIds: initialState.challengeIds,
        challenges: {
          ...initialState.challenges,
          ...userState.challenges,
        },
        version: initialState.version,
      }
    : userState;
};

export const processInteraction = (state, action, send) =>
  processChallenges(
    processAchievements(
      processTotalsAndStreaks(touch(state, action), action),
      action,
      send
    ),
    action,
    send
  );
