import { createTheme } from "@mui/material/styles";

const lightPalettes = {
  primary: {
    main: "#153e70",
  },
  secondary: {
    main: "#257eaa",
  },
  // used for the card background
  tertiary: {
    main: "#ede9e0",
  },
  info: {
    main: "#03a9f4",
    contrastText: "white",
  },
};

const darkPalettes = {
  primary: {
    main: "#03a9f4",
  },
  secondary: {
    main: "#03a9f4",
  },
  // used for the card background
  tertiary: {
    main: "#ede9e0",
  },
  info: {
    main: "#03a9f4",
    contrastText: "white",
  },
  text: {
    primary: "#ffffff",
    secondary: "rgba(255, 255, 255, 0.8)",
    disabled: "rgba(255, 255, 255, 0.7)",
  },
  action: {
    active: "#03a9f4",
    hover: "rgba(255, 255, 255, 0.3)",
    selected: "rgba(255, 255, 255, 0.6)",
  },
  background: {
    paper: "#343434",
    default: "#232323",
  },
};

export const theme = createTheme({
  palette: {
    mode: "light",
    ...lightPalettes,
  },
});

export const modeTheme = (mode) =>
  createTheme({
    palette: {
      mode: mode,
      ...(mode === "dark" ? darkPalettes : lightPalettes),
    },
  });
