import React from "react";
import { useBranding } from "providers/ProvideBranding";
import Typography from "@mui/material/Typography";

const Copyright = () => {
  const { rawString } = useBranding();
  const description = rawString("app.website.displayName");
  const url = rawString("app.website.url");
  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {"Copyright © "}
      <a
        target="_blank"
        rel="noreferrer"
        href={url}
        style={{ textDecoration: "none" }}
      >
        <Typography display="inline" variant="subtitle2" color="primary">
          {description}
        </Typography>
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
      &nbsp;v{process.env.REACT_APP_VERSION}
    </Typography>
  );
};

export default Copyright;
