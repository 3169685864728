import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const MyToolbarCenter = ({ title, children, left }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      {title && (
        <Typography
          align="center"
          sx={left ? null : { flexGrow: 1 }}
          variant="h6"
        >
          {title}
        </Typography>
      )}
      {children && (
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default MyToolbarCenter;
