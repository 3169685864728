import React, { createContext, useContext, useState, useEffect } from "react";
import SnackbarContent from "@mui/material/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import AwardIcon from "@mui/icons-material/MilitaryTech";
import { Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import * as routes from "routes";

const announcementContext = createContext();

export const ProvideAnnouncements = ({ children }) => {
  const ctx = useAnnouncementManager();
  return (
    <announcementContext.Provider value={ctx}>
      {children}
    </announcementContext.Provider>
  );
};

export const AchievementMessage = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography align="center" sx={{ mb: 1, ml: 0.5 }}>
        Congratulations! You've unlocked a Achievement.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <TrophyIcon />
        <Typography sx={{ ml: 1 }}>{message}</Typography>
      </Box>
      <Button
        fullWidth
        component={Link}
        to={routes.PROGRESS + "?tab=achievements"}
        sx={{ color: "white" }}
        type="submit"
        size="small"
      >
        View
      </Button>
    </Box>
  );
};

export const ChallengeMessage = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography align="center" sx={{ mb: 1, ml: 0.5 }}>
        Congratulations! You've unlocked a Challenge.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <AwardIcon />
        <Typography sx={{ ml: 1 }}>{message}</Typography>
      </Box>
      <Button
        fullWidth
        component={Link}
        to={routes.PROGRESS + "?tab=challenges"}
        sx={{ color: "white" }}
        type="submit"
        size="small"
      >
        View
      </Button>
    </Box>
  );
};

const useAnnouncementManager = () => {
  const [message, setMessage] = useState();

  const send = (message) => {
    setMessage(message);
  };

  const handleClose = () => {
    setMessage(null);
  };

  return { message, send, open: Boolean(message), handleClose };
};

export const useAnnouncement = () => {
  return useContext(announcementContext);
};

export const Announcement = React.forwardRef(function Announcement(props, ref) {
  return <SnackbarContent ref={ref} {...props} />;
});

export const Announements = ({ celebrate }) => {
  const { message, open, handleClose } = useAnnouncement();
  useEffect(() => {
    celebrate(!!message);
  }, [message]);
  return (
    <Snackbar
      open={open}
      autoHideDuration={30000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      {message && (
        <Announcement
          message={message}
          action={
            <IconButton
              onClick={handleClose}
              sx={{ color: "white" }}
              type="submit"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          }
        />
      )}
    </Snackbar>
  );
};
