import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "./ProvideApi";
import { useAlert } from "advancement-solutions-components/dist/providers";
import * as routes from "routes";
import { wrapError } from "../utils";
import { useAchievementContext } from "providers/ProvideAchievements";

const authContext = createContext();

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

function useProvideAuth() {
  const { api, authApi } = useApi();
  const { send } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { load } = useAchievementContext();

  const login = async (values, location) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`/public/auth`, values);
      await load();
      if (location.state) {
        history.push(location.state.from);
      } else {
        history.push(routes.HOME);
      }
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithReset = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`/public/auth/${values.code}/reset`, values);
      await load();
      history.push(routes.HOME);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithCode = async (code) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.get(`/public/auth/${code}`);
      await load();
      history.push(routes.HOME);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`/public/registration`, values);
      history.push(routes.PAYMENT);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const updateProfile = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await authApi.post(`/api/user`, values);
      send({ message: "Profile saved.", severity: "info" });
    } catch (e) {
      const error = wrapError(e);
      send({
        message: `Profile not saved: ${error.message}`,
        severity: "error",
      });
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await authApi.post(`/api/auth/change-password`, values);
      send({ message: "Password updated.", severity: "info" });
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const requestReset = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`/public/auth/reset`, values);
      send({
        message: "Your request has been sent. An email should arrive shortly.",
        severity: "info",
      });
    } catch (e) {
      send({
        message: "There was a problem with your request.",
        severity: "error",
      });
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const requestOtp = async (values) => {
    try {
      setIsLoading(true);
      setError(null);
      await api.post(`/public/auth/otp`, values);
      send({
        message: "Your request has been sent. An email should arrive shortly.",
        severity: "info",
      });
    } catch (e) {
      send({
        message: "There was a problem with your request.",
        severity: "error",
      });
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };

  const init = () => {
    setError(null);
  };

  const logout = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await authApi.delete(`/api/auth`);
    } catch (e) {
      send({
        message: "There was a problem with your request.",
        severity: "error",
      });
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
      history.push(routes.LOGIN);
    }
  };

  useEffect(() => {
    return () => false;
  }, []);

  return {
    login,
    loginWithCode,
    loginWithReset,
    register,
    updateProfile,
    changePassword,
    requestReset,
    requestOtp,
    logout,
    isLoading,
    error,
    init,
  };
}

export const useAuth = () => {
  return useContext(authContext);
};

export default ProvideAuth;
